import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import CartView from "../views/CartView.vue";
import EmailConfirmationView from "../views/EmailConfirmationView.vue";
import ForgotPasswordView from "../views/ForgotPasswordView.vue";
import NewUserInviteView from "../views/NewUserInviteView.vue";
import LoginView from "../views/LoginView.vue";
import OpenIDView from "../views/OpenIDView.vue";
import PatientPortalView from "../views/PatientPortalView.vue";
import PaymentView from "../views/PaymentView.vue";
import PhotosView from "../views/PhotosView.vue";
import PhotoVerifyView from "../views/PhotoVerifyView.vue";
import ProductView from "../views/ProductView.vue";
import RXProductView from "../views/RXProductView.vue";
import BasicInfoView from "../views/Questionnaire/BasicInfoView.vue";
import ChargeConfirmationView from "../views/Questionnaire/ChargeConfirmationView.vue";
import ChooseMedicationView from "../views/Questionnaire/ChooseMedicationView.vue";
import ChooseQuantityView from "../views/Questionnaire/ChooseQuantityView.vue";
import ModifyChooseQuantityView from "../views/Modify/ChooseQuantityView.vue";
import ChooseShippingView from "../views/Questionnaire/ChooseShippingView.vue";
import ErrorView from "../views/Questionnaire/ErrorView.vue";
import IdentityUploadView from "../views/Questionnaire/IdentityUploadView.vue";
import NotYetView from "../views/Questionnaire/NotYetView.vue";
import PhotosUpdateComplete from "../views/Questionnaire/PhotosUpdateCompleteView.vue";
import ProductReviewView from "../views/Questionnaire/ProductReviewView.vue";
import QuestionsView from "../views/Questionnaire/QuestionsView.vue";
import RefillChargeConfirmationView from "../views/Questionnaire/RefillChargeConfirmationView.vue";
import RefillCompleteView from "../views/Questionnaire/RefillCompleteView.vue";
import ScheduleVisitView from "../views/Questionnaire/ScheduleVisitView.vue";
import ShippingPaymentInfoView from "../views/Questionnaire/ShippingPaymentInfoView.vue";
import SignUpView from "../views/Questionnaire/SignUpView.vue";
import VisitCompleteView from "../views/Questionnaire/VisitCompleteView.vue";
import QuestionnaireView from "../views/QuestionnaireView.vue";
import RefillView from "../views/RefillView.vue";
import ModifyView from "../views/ModifyView.vue";
import GetStartedView from "../views/Questionnaire/GetStartedView.vue";
import AboutUsView from "../views/AboutUsView.vue";
import CheckoutView from "../views/Questionnaire/CheckoutView.vue";
import ChooseQuestionnaireTypeView from "../views/ChooseQuestionnaireTypeView.vue";
import HomepageView from "../views/HomepageView.vue";
import PharmacyReports from "../views/Pharmacy/PharmacyReportView.vue";
import SubChargePaymentView from "@/views/SubChargePaymentView.vue";
import MaintenanceView from "@/views/MaintenanceView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomepageView,
  },
  {
    path: "/",
    name: "hometwo",
    component: () => import(/* webpackChunkName: "hometwo" */ `../views/HomeRetailView2.vue`),
  },
  {
    path: "/",
    name: "salon",
    component: () => import(/* webpackChunkName: "salon" */ `../views/HomeRetailView3.vue`),
  },
  {
    path: "/",
    name: "singleProduct",
    component: () => import(/* webpackChunkName: "singleProduct" */ `../views/SingleProductSalesView.vue`),
  },
  {
    path: "/",
    name: "singleProgram",
    component: () => import(/* webpackChunkName: "singleProgram" */ `../views/HomeSingleProgramView.vue`),
  },
  {
    path: "/about",
    name: "about",
    component: AboutUsView,
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import(/* webpackChunkName: "faq" */ "../views/FAQView.vue"),
  },
  {
    path: "/signin",
    name: "signin",
    component: LoginView,
    props: (route) => ({ ReturnPath: route.query.r }),
  },
  {
    path: "/openid",
    name: "openid",
    component: OpenIDView,
    props: (route) => ({ vm: route.query.vm }),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import(/* webpackChunkName: "admin" */ "../views/AdminView.vue"),
    children: [
      // {
      //   path: "/admin/maintenance",
      //   name: "adminmaintenance",
      //   component: () => import(/* webpackChunkName: "admin" */ "../views/Admin/MaintenanceView.vue"),
      // },
      {
        path: "/admin/dashboard",
        name: "admindashboard",
        component: () => import(/* webpackChunkName: "admin" */ "../views/Admin/DashboardView.vue"),
      },
      {
        path: "/admin/settings",
        name: "adminsettings",
        component: () => import(/* webpackChunkName: "admin" */ "../views/Admin/SettingsView.vue"),
      },
      {
        path: "/admin/settings/edituser",
        name: "adminsettingsedituser",
        component: () => import(/* webpackChunkName: "admin" */ "../views/Admin/EditUserView.vue"),
      },
      {
        path: "/admin/identityproviders",
        name: "identityproviders",
        component: () => import(/* webpackChunkName: "admin" */ "../views/Admin/IdentityProvidersView.vue"),
      },
      {
        path: "/admin/promotions",
        name: "promotions",
        component: () => import(/* webpackChunkName: "admin" */ "../views/Admin/PromotionsView.vue"),
      },
      {
        path: "/admin/reports",
        name: "reports",
        component: () => import(/* webpackChunkName: "admin" */ "../views/Admin/ReportsView.vue"),
      },
      // {
      //   path: "/admin/cta/all",
      //   name: "admincalltoactionall",
      //   component: () => import(/* webpackChunkName: "admin" */ "../views/CallToActions/AllView.vue"),
      //   props: true,
      // },
      {
        path: "/admin/cta/details/:adverstisementID",
        name: "admincalltoactiondetails",
        component: () => import(/* webpackChunkName: "admin" */ "../views/CallToActions/DetailsView.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/affiliateadmin",
    name: "affiliateadmin",
    component: () => import(/* webpackChunkName: "affiliateadmin" */ "../views/AffiliateAdminView.vue"),
    children: [
      {
        path: "/affiliateadmin/dashboard",
        name: "affiliateadmindashboard",
        component: () => import(/* webpackChunkName: "affilateadmin" */ "../views/AffiliateAdmin/DashboardView.vue"),
      },
      // {
      //   path: "/affiliateadmin/maintenance",
      //   name: "affiliateadminmaintenance",
      //   component: () => import(/* webpackChunkName: "affiliateadmin" */ "../views/AffiliateAdmin/MaintenanceView.vue"),
      // },
      {
        path: "/affiliateadmin/promotions",
        name: "affiliateadminpromotion",
        component: () => import(/* webpackChunkName: "affiliateadmin" */ "../views/AffiliateAdmin/PromotionView.vue"),
      },
      {
        path: "/affiliateadmin/reports",
        name: "affiliateadminreports",
        component: () => import(/* webpackChunkName: "affiliateadmin" */ "../views/AffiliateAdmin/ReportView.vue"),
      },
      {
        path: "/affiliateadmin/report-details/:reportId/name/:reportName",
        name: "affiliateadminreportparameter",
        component: () => import(/* webpackChunkName: "affiliateadmin" */ "../views/AffiliateAdmin/ReportParameterView.vue"),
        props: true,
      },
      {
        path: "/chart-details/:chartReportId/name/:reportName",
        name: "affiliateadminchartreview",
        component: () => import(/* webpackChunkName: "affiliateadmin" */ "../views/AffiliateAdmin/ReportParameterView.vue"),
        props: true,
      },
      {
        path: "/affiliateadmin/cta/all",
        name: "affiliateadmincalltoactionall",
        component: () => import(/* webpackChunkName: "affiliateadmin" */ "../views/CallToActions/AllView.vue"),
        props: true,
      },
      {
        path: "/affiliateadmin/cta/details/:adverstisementID",
        name: "affiliateadmincalltoactiondetails",
        component: () => import(/* webpackChunkName: "affiliateadmin" */ "../views/CallToActions/DetailsView.vue"),
        props: true,
      },
      {
        name: "/affiliateadmin/customerlookup",
        path: "customerlookup",
        component: () => import(/* webpackChunkName: "affiliateadmin" */ "../views/AffiliateAdmin/CustomerLookupView.vue"),
      },
      {
        name: "/affiliateadmin/customerdetail",
        path: "customerdetail",
        component: () => import(/* webpackChunkName: "affiliateadmin" */ "../views/AffiliateAdmin/CustomerDetailView.vue"),
        props: (route) => ({ dateOfBirthEmail: route.query.dateOfBirthEmail, dateOfBirth: route.query.dateOfBirth, lastName: route.query.lastName, email: route.query.email, id: route.query.id }),
      },
    ],
  },
  {
    path: "/apionly",
    name: "apionly",
    component: () => import(/* webpackChunkName: "api" */ "../views/APIView.vue"),
  },
  {
    path: "/go",
    name: "choosequestionnairetype",
    component: ChooseQuestionnaireTypeView,
    props: (route) => ({ questionnaireTypeID: route.query.t, productRxID: route.query.p, productRxSubscriptionFrequencyID: route.query.s }),
  },
  {
    path: "/cart",
    name: "cart",
    component: CartView,
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import(/* webpackChunkName: "checkout" */ "../views/CheckoutView.vue"),
    children: [
      {
        path: "/checkout/signin",
        name: "checkoutsignin",
        component: () => import(/* webpackChunkName: "checkout" */ "../views/Checkout/CheckoutLoginView.vue"),
      },
      {
        path: "/checkout/signup",
        name: "checkoutsignup",
        component: () => import(/* webpackChunkName: "checkout" */ "../views/Checkout/CheckoutSignupView.vue"),
      },
      {
        path: "/checkout/checkout",
        name: "checkoutshipping",
        component: () => import(/* webpackChunkName: "checkout" */ "../views/Checkout/CheckoutView.vue"),
      },
      {
        path: "/checkout/review",
        name: "checkoutreview",
        component: () => import(/* webpackChunkName: "checkout" */ "../views/Checkout/CheckoutReviewView.vue"),
      },
    ],
  },
  {
    path: "/Home/ConfirmEmail",
    name: "emailconfirmation",
    component: EmailConfirmationView,
    props: (route) => ({ code: route.query.code }),
  },
  {
    path: "/Home/ForgotPassword",
    name: "forgotpassword",
    component: ForgotPasswordView,
  },
  {
    path: "/Home/NewUserInvite",
    name: "newuserinvite",
    component: NewUserInviteView,
    props: (route) => ({ code: route.query.code }),
  },
  {
    path: "/product",
    name: "product",
    component: ProductView,
    props: (route) => ({ id: route.query.id, type: route.query.type }),
  },
  {
    path: "/rxproduct",
    name: "rxproduct",
    component: RXProductView,
    props: (route) => ({ id: route.query.id, code: route.query.code }),
  },
  {
    path: "/patientportal",
    name: "patientportal",
    component: PatientPortalView,
  },
  {
    path: "/photoverify",
    name: "photoverify",
    component: PhotoVerifyView,
  },
  {
    path: "/support",
    name: "support",
    component: () => import(/* webpackChunkName: "support" */ "../views/SupportView.vue"),
    children: [
      {
        name: "/support/dashboard",
        path: "dashboard",
        component: () => import(/* webpackChunkName: "support" */ "../views/Support/DashboardView.vue"),
      },
      {
        name: "/support/customerlookup",
        path: "customerlookup",
        component: () => import(/* webpackChunkName: "support" */ "../views/Support/CustomerLookupView.vue"),
      },
      {
        name: "/support/customerdetail",
        path: "customerdetail",
        component: () => import(/* webpackChunkName: "support" */ "../views/Support/CustomerDetailView.vue"),
        props: (route) => ({ dateOfBirthEmail: route.query.dateOfBirthEmail, dateOfBirth: route.query.dateOfBirth, lastName: route.query.lastName, email: route.query.email, id: route.query.id }),
      },
      {
        name: "/support/reports",
        path: "reports",
        component: () => import(/* webpackChunkName: "support" */ "../views/Support/SupportReportView.vue"),
      },
      {
        path: "/support/report-details/:reportId/name/:reportName",
        name: "supportreportparameter",
        component: () => import(/* webpackChunkName: "support" */ "../views/Support/SupportReportParameterView.vue"),
        props: true,
      },
      {
        name: "/support/supportunreadmessages",
        path: "supportunreadmessages",
        component: () => import(/* webpackChunkName: "support" */ "../views/Support/SupportUnreadMessagesView.vue"),
      },
      {
        path: "/support/photoverify",
        name: "supportphotoverify",
        component: PhotoVerifyView,
      },
    ],
  },
  {
    path: "/rx",
    name: "rx",
    component: () => import(/* webpackChunkName: "rx" */ "../views/RxView.vue"),
    children: [
      {
        name: "/rx/payment",
        path: "payment",
        component: () => import(/* webpackChunkName: "rx" */ "../views/Rx/PaymentView.vue"),
        children: [
          {
            name: "/rx/payment/shippingpaymentinfo",
            path: "shippingpaymentinfo",
            component: () => import(/* webpackChunkName: "rx" */ "../views/Rx/ShippingPaymentInfoView.vue"),
          },
          {
            name: "/rx/payment/error",
            path: "error",
            component: ErrorView,
          },
          {
            name: "/rx/payment/rxreview",
            path: "rxreview",
            component: () => import(/* webpackChunkName: "rx" */ "../views/Rx/RxReview.vue"),
          },
          {
            name: "/rx/payment/chargecomplete",
            path: "chargecomplete",
            component: () => import(/* webpackChunkName: "rx" */ "../views/Rx/ChargeCompleteView.vue"),
          },
          {
            name: "/rx/payment/chargeconfirmation",
            path: "chargeconfirmation",
            component: () => import(/* webpackChunkName: "rx" */ "../views/Rx/ChargeConfirmationView.vue"),
          },
        ],
      },
      {
        name: "/rx/refill",
        path: "refill",
        component: () => import(/* webpackChunkName: "rx" */ "../views/Rx/RefillView.vue"),
        children: [
          {
            name: "/rx/refill/questions",
            path: "questions",
            component: QuestionsView,
          },
          {
            name: "/rx/refill/shippingpaymentinfo",
            path: "shippingpaymentinfo",
            component: () => import(/* webpackChunkName: "rx" */ "../views/Rx/ShippingPaymentInfoView.vue"),
          },
          {
            name: "/rx/refill/error",
            path: "error",
            component: ErrorView,
          },
          {
            name: "/rx/refill/rxreview",
            path: "rxreview",
            component: () => import(/* webpackChunkName: "rx" */ "../views/Rx/RxReview.vue"),
          },
          {
            name: "/rx/refill/chargecomplete",
            path: "chargecomplete",
            component: () => import(/* webpackChunkName: "rx" */ "../views/Rx/ChargeCompleteView.vue"),
          },
          {
            name: "/rx/refill/chargeconfirmation",
            path: "chargeconfirmation",
            component: () => import(/* webpackChunkName: "rx" */ "../views/Rx/ChargeConfirmationView.vue"),
          },
        ],
      },
    ],
  },
  {
    name: "/subscription",
    path: "/subscription",
    component: () => import(/* webpackChunkName: "sub" */ "../views/Subscription/SubscriptionView.vue"),
    children: [
      {
        name: "/subscription/start",
        path: "start",
        component: () => import(/* webpackChunkName: "sub" */ "../views/Subscription/StartView.vue"),
      },
      {
        name: "/subscription/questions",
        path: "questions",
        component: QuestionsView,
      },
      {
        name: "/subscription/error",
        path: "error",
        component: ErrorView,
      },
      {
        name: "/subscription/subscriptionchoices",
        path: "subscriptionchoices",
        component: () => import(/* webpackChunkName: "sub" */ "../views/Subscription/SubscriptionsChoicesView.vue"),
      },
      {
        name: "/subscription/chargecomplete",
        path: "chargecomplete",
        component: () => import(/* webpackChunkName: "sub" */ "../views/Subscription/ChargeCompleteView.vue"),
      },
      {
        name: "/subscription/checkout",
        path: "checkout",
        component: () => import(/* webpackChunkName: "sub" */ "../views/Subscription/CheckoutView.vue"),
      },
    ],
  },
  {
    path: "/pharmacy",
    name: "pharmacy",
    component: () => import(/* webpackChunkName: "pharmacy" */ "../views/PharmacyView.vue"),
    children: [
      {
        name: "/pharmacy/dashboard",
        path: "dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/Pharmacy/DashboardView.vue"),
      },
      {
        name: "/pharmacy/customerlookup",
        path: "customerlookup",
        component: () => import(/* webpackChunkName: "pharmacy" */ "../views/Pharmacy/CustomerLookupView.vue"),
      },
      {
        name: "/pharmacy/customerdetail",
        path: "customerdetail",
        component: () => import(/* webpackChunkName: "pharmacy" */ "../views/Pharmacy/CustomerDetailView.vue"),
        props: (route) => ({ dateOfBirth: route.query.dateOfBirth, lastName: route.query.lastName, email: route.query.email, id: route.query.id }),
      },
      {
        name: "/pharmacy/addrx",
        path: "addrx",
        component: () => import(/* webpackChunkName: "pharmacy" */ "../views/Pharmacy/AddRxView.vue"),
      },
      {
        name: "/pharmacy/addtracking",
        path: "addtracking",
        component: () => import(/* webpackChunkName: "pharmacy" */ "../views/Pharmacy/AddTrackingView.vue"),
        props: (route) => ({ id: route.query.id }),
      },
      {
        name: "/pharmacy/addotctracking",
        path: "addotctracking",
        component: () => import(/* webpackChunkName: "pharmacy" */ "../views/Pharmacy/AddOTCTrackingView.vue"),
        props: (route) => ({ id: route.query.id }),
      },
      {
        name: "/pharmacy/schedulemanagement",
        path: "schedulemanagement",
        component: () => import(/* webpackChunkName: "pharmacy" */ "../views/Pharmacy/ScheduleManagementView.vue"),
      },
      {
        name: "/pharmacy/reports",
        path: "reports",
        component: () => import(/* webpackChunkName: "pharmacy" */ "../views/Pharmacy/PharmacyReportView.vue"),
      },
      {
        path: "/pharmacy/report-details/:reportId/name/:reportName",
        name: "pharmacyreportparameter",
        component: () => import(/* webpackChunkName: "pharmacy" */ "../views/Pharmacy/PharmacyReportParameterView.vue"),
        props: true,
      },
      {
        name: "/pharmacy/pharmacyunreadmessages",
        path: "pharmacyunreadmessages",
        component: () => import(/* webpackChunkName: "pharmacy" */ "../views/Pharmacy/PharmacyUnreadMessagesView.vue"),
      },
      {
        name: "/pharmacy/totalordersshipped",
        path: "totalordersshipped",
        component: () => import(/* webpackChunkName: "pharmacy" */ "../views/Pharmacy/TotalOrdersShippedView.vue"),
      },
    ],
  },
  {
    path: "/physician",
    name: "physician",
    component: () => import(/* webpackChunkName: "dr" */ "../views/PhysicianView.vue"),
    children: [
      {
        name: "/physician/patientqueue",
        path: "patientqueue",
        component: () => import(/* webpackChunkName: "dr" */ "../views/Physician/PatientQueueView.vue"),
        props: (route) => ({ questionnaireID: route.query.q, affiliateID: route.query.a }),
      },
      {
        name: "/physician/visitschedule",
        path: "visitschedule",
        component: () => import(/* webpackChunkName: "dr" */ "../views/Physician/VisitScheduleView.vue"),
      },
      {
        name: "/physician/reports",
        path: "reports",
        component: () => import(/* webpackChunkName: "dr" */ "../views/Physician/PhysicianReportView.vue"),
      },
      {
        path: "/physician/report-details/:reportId/name/:reportName",
        name: "physicianreportparameter",
        component: () => import(/* webpackChunkName: "dr" */ "../views/Physician/PhysicianReportParameterView.vue"),
        props: true,
      },
      {
        name: "/physician/patientlookup",
        path: "patientlookup",
        component: () => import(/* webpackChunkName: "dr" */ "../views/Physician/PatientLookupView.vue"),
      },
    ],
  },
  {
    name: "questionnaire",
    path: "/questionnaire/",
    component: QuestionnaireView,
    props: (route) => ({ outsidePromo: route.query.code, productRxID: route.query.p, productRxSubscriptionFrequencyID: route.query.s, tryScreenForce: route.query.tsf }),
    children: [
      {
        name: "/questionnaire/modify",
        path: "modify",
        component: ModifyChooseQuantityView,
      },
      {
        name: "/questionnaire/basicinfo",
        path: "basicinfo",
        component: BasicInfoView,
      },
      {
        name: "/questionnaire/questions",
        path: "questions",
        component: QuestionsView,
      },
      {
        name: "/questionnaire/shippingpaymentinfo",
        path: "shippingpaymentinfo",
        component: ShippingPaymentInfoView,
      },
      {
        name: "/questionnaire/error",
        path: "error",
        component: ErrorView,
      },
      {
        name: "/questionnaire/choosemedication",
        path: "choosemedication",
        component: ChooseMedicationView,
      },
      {
        name: "/questionnaire/choosequantity",
        path: "choosequantity",
        component: ChooseQuantityView,
      },
      {
        name: "/questionnaire/chooseshipping",
        path: "chooseshipping",
        component: ChooseShippingView,
      },
      {
        name: "/questionnaire/productreview",
        path: "productreview",
        component: ProductReviewView,
      },
      {
        name: "/questionnaire/identityupload",
        path: "identityupload",
        component: IdentityUploadView,
      },
      {
        name: "/questionnaire/visitcomplete",
        path: "visitcomplete",
        component: VisitCompleteView,
      },
      {
        name: "/questionnaire/chargeconfirmation",
        path: "chargeconfirmation",
        component: ChargeConfirmationView,
      },
      {
        name: "/questionnaire/schedulevisit",
        path: "schedulevisit",
        component: ScheduleVisitView,
      },
      {
        name: "/questionnaire/signup",
        path: "signup",
        component: SignUpView,
      },
      {
        name: "/questionnaire/sorry",
        path: "sorry",
        component: NotYetView,
      },
      {
        name: "/questionnaire/checkout",
        path: "checkout",
        component: CheckoutView,
      },
      {
        name: "/questionnaire/getstarted",
        path: "getstarted",
        component: GetStartedView,
        props: (route) => ({ fromCheckout: route.query.fc }),
      },
    ],
  },
  {
    name: "photos",
    path: "/photos/",
    component: PhotosView,
    children: [
      {
        name: "/photos/identityupload",
        path: "identityupload",
        component: IdentityUploadView,
      },
      {
        name: "/photos/photosupdatecomplete",
        path: "photosupdatecomplete",
        component: PhotosUpdateComplete,
      },
      {
        name: "/photos/error",
        path: "error",
        component: ErrorView,
      },
    ],
  },
  {
    name: "payment",
    path: "/payment/",
    component: PaymentView,
    children: [
      {
        name: "/payment/basicinfo",
        path: "basicinfo",
        component: BasicInfoView,
      },
      {
        name: "/payment/shippingpaymentinfo",
        path: "shippingpaymentinfo",
        component: ShippingPaymentInfoView,
      },
      {
        name: "/payment/error",
        path: "error",
        component: ErrorView,
      },
      {
        name: "/payment/chooseshipping",
        path: "chooseshipping",
        component: ChooseShippingView,
      },
      {
        name: "/payment/productreview",
        path: "productreview",
        component: ProductReviewView,
      },
      {
        name: "/payment/refillcomplete",
        path: "refillcomplete",
        component: RefillCompleteView,
      },
      {
        name: "/payment/refillchargeconfirmation",
        path: "refillchargeconfirmation",
        component: RefillChargeConfirmationView,
      },
      {
        name: "/payment/sorry",
        path: "sorry",
        component: NotYetView,
      },
    ],
  },
  {
    name: "subchargepayment",
    path: "/subchargepayment/",
    component: SubChargePaymentView,
    children: [
      {
        name: "/subchargepayment/error",
        path: "error",
        component: ErrorView,
      },
      {
        name: "/subchargepayment/checkout",
        path: "checkout",
        component: CheckoutView,
      },
      {
        name: "/subchargepayment/complete",
        path: "complete",
        component: RefillCompleteView,
      },
      {
        name: "/subchargepayment/sorry",
        path: "sorry",
        component: NotYetView,
      },
    ],
  },
  {
    name: "refill",
    path: "/refill/",
    component: RefillView,
    props: (route) => ({ numprop: route.query.num, t: route.query.t }),
    children: [
      {
        name: "/refill/basicinfo",
        path: "basicinfo",
        component: BasicInfoView,
      },
      {
        name: "/refill/questions",
        path: "questions",
        component: QuestionsView,
      },
      {
        name: "/refill/shippingpaymentinfo",
        path: "shippingpaymentinfo",
        component: ShippingPaymentInfoView,
      },
      {
        name: "/refill/error",
        path: "error",
        component: ErrorView,
      },
      {
        name: "/refill/choosemedication",
        path: "choosemedication",
        component: ChooseMedicationView,
      },
      {
        name: "/refill/choosequantity",
        path: "choosequantity",
        component: ChooseQuantityView,
      },
      {
        name: "/refill/chooseshipping",
        path: "chooseshipping",
        component: ChooseShippingView,
      },
      {
        name: "/refill/productreview",
        path: "productreview",
        component: ProductReviewView,
      },
      {
        name: "/refill/identityupload",
        path: "identityupload",
        component: IdentityUploadView,
      },
      {
        name: "/refill/refillcomplete",
        path: "refillcomplete",
        component: RefillCompleteView,
      },
      {
        name: "/refill/refillchargeconfirmation",
        path: "refillchargeconfirmation",
        component: RefillChargeConfirmationView,
      },
      {
        name: "/refill/schedulevisit",
        path: "schedulevisit",
        component: ScheduleVisitView,
      },
      {
        name: "/refill/signup",
        path: "signup",
        component: SignUpView,
      },
      {
        name: "/refill/sorry",
        path: "sorry",
        component: NotYetView,
      },
    ],
  },
  {
    name: "modify",
    path: "/modify/",
    component: ModifyView,
    props: (route) => ({ numprop: route.query.num, t: route.query.t }),
    children: [
      {
        name: "/modify/questions",
        path: "questions",
        component: QuestionsView,
      },
      {
        name: "/modify/error",
        path: "error",
        component: ErrorView,
      },
      {
        name: "/modify/choosequantity",
        path: "choosequantity",
        component: ModifyChooseQuantityView,
      },
      {
        name: "/modify/checkout",
        path: "checkout",
        component: CheckoutView,
      },
      {
        name: "/modify/refillcomplete",
        path: "refillcomplete",
        component: RefillCompleteView,
      },
      {
        name: "/modify/sorry",
        path: "sorry",
        component: NotYetView,
      },
    ],
  },
  // {
  //   name: "maintenance",
  //   path: "/maintenance/",
  //   component: MaintenanceView,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
