
import Vue, { set } from "vue";
import mixins from "vue-typed-mixins";
import { mapGetters, mapMutations } from "vuex";
import { number, object, string, boolean } from "yup";
import router from "@/router";
import axios from "axios";
import store from "@/store";
import { AjaxReturnViewModel } from "@/interfaces/AjaxReturnViewModel";

export default Vue.extend({
  data() {
    return {
      code: null as string | null,
      emailToken: false as boolean | false,
      passwordToken: true as boolean | false,
      error: "" as string | "",
      loading: true as boolean | true,
      password: "" as string | "",
      confirmPassword: "" as string | "",
      eightChars: false,
      oneNumber: false,
      lowerCase: false,
      upperCase: false,
      match: false,
      enableSubmit: false,
      submitted: false,
      showPassForm: true,
    };
  },
  async mounted() {
    this.code = router.currentRoute.query.code?.toString();
    console.log(this.code);
    if (this.code == "") {
      this.error = "Invalid confirmation code or user";
      this.loading = false;
      return;
    }
    this.setLoading(true);
    var rsp = await store.dispatch("NewUserInvite", { code: this.code });
    this.setLoading(false);
    if (rsp?.data) {
      this.emailToken = true;
    } else {
      this.error = rsp.errors[0];
    }
    this.loading = false;
  },

  watch: {
    password: function () {
      this.validatePassword();
    },
    confirmPassword: function () {
      this.validatePassword();
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),

    async resetPassword() {
      if (this.password && this.confirmPassword && this.password === this.confirmPassword) {
        this.setLoading(true);
        const rsp = await store.dispatch("NewUserPasswordReset", { code: this.code, newPassword: this.password });
        if (rsp?.data && rsp.success === true) {
          this.passwordToken = true;

          this.$router.push("/signin");
        } else {
          this.passwordToken = false;
          this.error = rsp.errors[0];
          this.showPassForm = false;
        }
      } else {
        this.error = "Passwords do not match!";
      }
      this.setLoading(false);
    },

    validatePassword: function () {
      this.enableSubmit = false;

      if (this.password && this.password.length > 7) {
        this.eightChars = true;
      } else {
        this.eightChars = false;
      }
      var rgxON = RegExp("[0-9]");
      if (this.password && rgxON.test(this.password)) {
        this.oneNumber = true;
      } else {
        this.oneNumber = false;
      }
      var lcRGX = RegExp("[a-z]");
      if (this.password && lcRGX.test(this.password)) {
        this.lowerCase = true;
      } else {
        this.lowerCase = false;
      }
      var ucRGX = RegExp("[A-Z]");
      if (this.password && ucRGX.test(this.password)) {
        this.upperCase = true;
      } else {
        this.upperCase = false;
      }
      if (this.password && this.confirmPassword && this.password == this.confirmPassword) {
        this.match = true;
      } else {
        this.match = false;
      }

      if (this.eightChars && this.oneNumber && this.lowerCase && this.upperCase && this.match) {
        this.enableSubmit = true;
      }
    },
  },
});
