
import mixins from "vue-typed-mixins";
import CustomValidation from "../../mixins/CustomValidation";
import { string, object, bool } from "yup";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { mapGetters } from "vuex";
import { PropType } from "vue";
import PicInputVueComponentVue from "@/components/PicInputVueComponent.vue";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { PatientViewModel } from "@/interfaces/PatientViewModel";
import { PhotosViewModel } from "@/interfaces/PhotosViewModel";

export default mixins(CustomValidation).extend({
  mixins: [CustomValidation],
  name: "identity-upload",
  props: {
    q: Object as PropType<QuestViewModel>,
    isRefill: { type: Boolean, default: false },
    isPayment: { type: Boolean, default: false },
    isPhotoUpdate: { type: Boolean, default: false },
  },
  data() {
    return {
      schema: object().shape({
        selfieUrlInUse: bool(),
        selfieBase64: string()
          .nullable()
          .when("selfieUrlInUse", {
            is: (selfieUrlInUse: boolean) => !selfieUrlInUse,
            then: string().nullable().required("Selfie is required.").typeError("Selfie is required."),
          }),
        officialIDUrlInUse: bool(),
        officialIDBase64: string()
          .nullable()
          .when("officialIDUrlInUse", {
            is: (officialIDUrlInUse: boolean) => !officialIDUrlInUse,
            then: string().nullable().required("Official ID is required.").typeError("Official ID is required."),
          }),
      }),
      selfieBase64: null as string | null,
      officialIDBase64: null as string | null,
      selfieUrl: "" as string,
      officialIDUrl: "" as string,
      officialIDUrlInUse: false,
      selfieUrlInUse: false,
      showUpdateRequiredError: false,
      showCantMatchError: false,
    };
  },
  created() {
    this.importData(this.Patient);
    this.setupValidation(this.schema);
  },
  watch: {
    Patient(newVal) {
      this.importData(newVal);
    },
    base64Photos(newVal) {
      this.selfieBase64 = newVal.SelfieBase64;
      this.officialIDBase64 = newVal.OfficialIDBase64;
    },
    /*selfieBase64(newVal, oldVal) {
      if (newVal != oldVal) {
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $(".official-id-scroll").offset().top - 25,
          },
          2000
        );
      }
    },*/
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
    }),
    Base64Photos(): PhotosViewModel {
      return this.q.base64Photos;
    },
    Patient(): PatientViewModel {
      return this.q.patient;
    },
    title(): string {
      if (!this.isPhotoUpdate) return "Smile! We need to verify your ID.";
      else return "Update Your Smile!";
    },
    stepType(): string {
      return this.isPhotoUpdate ? "none" : "new";
    },
  },
  methods: {
    btnOfficialIDChangePhoto_click() {
      this.officialIDUrlInUse = false;
      this.showUpdateRequiredError = false;
      this.showCantMatchError = false;
    },
    btnSelfieChangePhoto_click() {
      this.selfieUrlInUse = false;
      this.showUpdateRequiredError = false;
      this.showCantMatchError = false;
    },
    importData(newVal: PatientViewModel | null) {
      if (!newVal) return;

      this.officialIDUrl = newVal.photo1Path ? newVal.photo1Path : "";
      if (this.officialIDUrl && this.officialIDUrl.length > 0) {
        this.officialIDUrlInUse = true;
      }
      this.selfieUrl = newVal.photo2Path ? newVal.photo2Path : "";
      if (this.selfieUrl && this.selfieUrl.length > 0) {
        this.selfieUrlInUse = true;
      }
    },
    getUpdatedData() {
      var p = Object.assign({}, this.Base64Photos);
      p.selfieBase64 = this.selfieBase64 ? this.selfieBase64 : "";
      p.officialIDBase64 = this.officialIDBase64 ? this.officialIDBase64 : "";
      return p;
    },
    next_click() {
      this.errors = [];
      this.schema
        .validate(this, { abortEarly: false })
        .then(() => {
          this.errors = [];
          var data = this.getUpdatedData();

          if ((!this.officialIDUrlInUse && !this.officialIDBase64) || (!this.selfieUrlInUse && !this.selfieBase64)) {
            return;
          }

          if (this.isPhotoUpdate && this.officialIDUrlInUse && this.selfieUrlInUse) {
            this.showUpdateRequiredError = true;
            return;
          }

          if (!this.officialIDUrlInUse && !this.selfieUrlInUse && this.officialIDBase64 && this.officialIDBase64 === this.selfieBase64) {
            this.showCantMatchError = true;
            return;
          }

          if (this.officialIDUrlInUse && this.selfieUrlInUse) {
            data.dontSave = true;
          } else {
            delete data.dontSave;
          }

          this.$emit("saveandnavigate", "+", data);
        })
        .catch((err: { inner: any[] }) => {
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
          });
        });
    },
    prev_click() {
      this.errors = [];
      this.schema
        .validate(this, { abortEarly: false })
        .then(() => {
          this.errors = [];
          var data = this.getUpdatedData();

          if ((!this.officialIDUrlInUse && !this.officialIDBase64) || (!this.selfieUrlInUse && !this.selfieBase64)) {
            return;
          }

          if (this.isPhotoUpdate && this.officialIDUrlInUse && this.selfieUrlInUse) {
            this.showUpdateRequiredError = true;
            return;
          }

          if (this.officialIDUrlInUse && this.selfieUrlInUse) {
            data.dontSave = true;
          } else {
            delete data.dontSave;
          }

          this.$emit("saveandnavigate", "-", data);
        })
        .catch((err: { inner: any[] }) => {
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
          });
        });
    },
  },
  components: { QuestWrapperComponent, PicInputVueComponentVue },
});
