
import { OrderViewModel } from "@/interfaces/OrderViewModel";
import moment from "moment";
import router from "@/router";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { StyleValue } from "vue/types/jsx";
import store from "@/store";
import { AccountSettingsViewModel } from "../interfaces/AccountSettingsViewModel";

export default {
  name: "sidebar-menu",
  props: {},
  data() {
    return {
      isMobile: false,
      hideHeader: false,
      isDropFrameVisible: false,
      isMenuOpen: false,
      sidebarWidth: "250px",
      isNavOpen: true,
      sidebarOpen: false,
      accountSettings: null as AccountSettingsViewModel | null,
      isButtonSelected: {
        button1: false,
        button2: false,
        button3: false,
        button4: false,
        button5: false,
        button6: false,
        button7: false,
      } as { [key: string]: boolean },
      activeSelected: "" as string,
      isSecondaryButtonSelected: {} as { [key: string]: boolean },
      reportsMenu: false as boolean,
      pharmacyReportsMenu: false as boolean,
      reportNames: {} as {
        [key: number]: {
          ReportName: string;
          Category: string;
        };
      },

      showAdminMenu: false as boolean,
      mobileThemeMenu: false as boolean,
      mobileReportsMenu: false as boolean,
      reportCategories: true as boolean,
      expandedCategories: {} as { [key: string]: boolean },
      mobileReports: false as boolean,
    };
  },
  async mounted() {
    if (this.SignedIn && (this.Role === "AffiliateAdmin" || this.Role === "Pharmacy" || this.Role === "Support" || this.Role === "Physician")) {
      await this.GetReportNames();
    }
    this.setLoading(false);
  },
  computed: {
    ...mapGetters({
      Affiliate: "getAffiliate",
      Theme: "getTheme",
      Content: "getContent",
      Loading: "getLoading",
      SignedIn: "getSignedIn",
      Role: "getRole",
      IsCollectJSLoaded: "getIsCollectJSLoaded",
      PhotoPath: "getPhotoPath",
      ShowSidebar: "getShowSidebarAdmin",
      ScheduledMaintenance: "getScheduledMaintenance",
      ExpectedMaintenanceStart: "getExpectedMaintenanceStart",
    }),
    SiteLogoStyle(): StyleValue {
      console.log(this.Theme.SiteLogoStyle);
      if (this.Theme.SiteLogoStyle) return this.Theme.SiteLogoStyle;
      else
        return {
          "max-height": "65px",
        };
    },
    dashboardRoute() {
      if (this.Role === "Admin") {
        return "/admin/dashboard";
      } else if (this.Role === "AffiliateAdmin") {
        return "/affiliateadmin/dashboard";
      } else if (this.Role === "Pharmacy") {
        return "/pharmacy/dashboard";
      } else {
        return "/";
      }
    },
    groupedReportNames() {
      const reportArray = Object.entries(this.reportNames).map(([key, value]) => ({
        key: Number(key), // Ensure the key is a number
        ...value,
      }));
      reportArray.sort((a, b) => a.ReportName.localeCompare(b.ReportName));
      return reportArray.reduce((acc: any, report: any) => {
        const category = report.Category.toUpperCase();
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(report);
        return acc;
      }, {});
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setSidebarAdmin: "setSidebarAdmin",
      setCustomerLookup: "setCustomerLookup",
      setReportTableData: "setReportTableData",
      setFilteredData: "setFilteredData",
      setActiveFilters: "setActiveFilters",
      setCurrentPage: "setCurrentPage",
      setPageSize: "setPageSize",
    }),
    async signOut() {
      await store.dispatch("SignOut");
      router.push("/signin");
    },
    async showSettings() {
      this.$emit("show-settings");
      this.setSidebarAdmin(false);
    },
    menuToggle() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    mobileMenuReports() {
      this.mobileReports = !this.mobileReports;
    },
    toggleCategory(category: any) {
      this.$set(this.expandedCategories, category, !this.expandedCategories[category]);
    },
    getCategoryIcon(category: any) {
      return this.expandedCategories[category] ? "chevron-down" : "chevron-right";
    },
    navToggle() {
      if (this.isNavOpen) {
        this.sidebarWidth = "0px";
      } else {
        this.sidebarWidth = "250px";
      }
      this.isNavOpen = !this.isNavOpen;
    },

    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    // navToCTASpace(adminBool: boolean) {
    //   if (adminBool) {
    //     this.$router.push("/admin/cta/all");
    //   } else {
    //     this.$router.push("/affiliateadmin/cta/all");
    //   }
    // },
    // displayMaintenance() {
    //   const messageStart = "Maintenance is expected to start at ";
    //   const messageEnd = " and is expected to last 30 minutes.";
    //   const unexpectedChecker = this.ScheduledMaintenance.indexOf("Unscheduled");
    //   if (this.ScheduledMaintenance && this.ScheduledMaintenance !== "" && !unexpectedChecker) {
    //     const startIdx = this.ScheduledMaintenance.indexOf(messageStart) + messageStart.length;
    //     const endIdx = this.ScheduledMaintenance.indexOf(messageEnd);
    //     const timeToDisplay = this.ScheduledMaintenance.substring(startIdx, endIdx);

    //     const localTime = moment.utc(timeToDisplay).local();
    //     const formattedTime = localTime.format("M/D/YYYY h:mm A");

    //     this.$bvToast.toast(`${messageStart}${formattedTime}${messageEnd}`, {
    //       title: "Upcoming Maintenance",
    //       variant: "info",
    //       autoHideDelay: 10000,
    //       appendToast: true,
    //     });
    //   } else {
    //     this.$bvToast.toast(`${this.ScheduledMaintenance}`, {
    //       title: "Ongoing Maintenance",
    //       variant: "info",
    //       autoHideDelay: 10000,
    //       appendToast: true,
    //     });
    //   }
    // },
    showSelected(button: string, routePath?: string) {
      for (const key in this.isButtonSelected) {
        this.isButtonSelected[key] = false;
      }

      this.isButtonSelected[button] = true;
      this.activeSelected = button;
      this.reportsMenu = false;
      if (routePath) {
        this.$router.push(routePath);
      }
      this.setSidebarAdmin(false);
      this.isSecondaryButtonSelected.button1 = false;
      this.mobileReportsMenu = false;

      Object.keys(this.reportNames).forEach((key) => {
        this.$set(this.isSecondaryButtonSelected, key, false);
      });
    },

    handleCustomerLookupClick() {
      this.setCustomerLookup([]);
      if (this.Role === "Support") {
        this.showSelected("button2", "/support/customerlookup");
      }
      if (this.Role === "Pharmacy") {
        this.showSelected("button2", "/pharmacy/customerlookup");
      }
      if (this.Role === "AffiliateAdmin") {
        this.showSelected("button2", "/affiliateadmin/customerlookup");
      }
    },

    showReportsSecondaryMenu(button: string, routePath: string) {
      for (const key in this.isButtonSelected) {
        this.isButtonSelected[key] = false;
      }

      this.isButtonSelected[button] = true;
      this.activeSelected = button;

      this.reportsMenu = true;
      const targetWord = ["details"];

      if (targetWord.every((word) => this.$route.path.includes(word))) {
        return;
      }

      //Clear ReportData when clicking "Reports" button
      this.setReportTableData([]);
      this.setFilteredData([]);
      this.setActiveFilters({});
      this.setCurrentPage(1);
      this.setPageSize(30);

      this.$router.push(routePath);
    },
    showThemeMenu(button: string) {
      for (const key in this.isButtonSelected) {
        this.isButtonSelected[key] = false;
      }

      this.isButtonSelected[button] = true;
      this.activeSelected = button;

      this.mobileThemeMenu = !this.mobileThemeMenu;
    },
    showReportOptionsMobile(button: string) {
      for (const key in this.isButtonSelected) {
        this.isButtonSelected[key] = false;
      }

      this.isButtonSelected[button] = true;
      this.activeSelected = button;

      this.mobileReportsMenu = !this.mobileReportsMenu;
    },
    showReportClickedMobile(route: string) {
      this.$router.push(route);
      this.setSidebarAdmin(false);
    },
    showCTAClickedMobile(route: string) {
      this.$router.push(route);
      this.setSidebarAdmin(false);
    },
    closeReportsMenu() {
      this.reportsMenu = false;
    },

    showSecondarySelected(reportKey: number, reportValue: string) {
      // Reset all selections to false
      Object.keys(this.reportNames).forEach((key) => {
        this.$set(this.isSecondaryButtonSelected, key, false);
      });

      // Set the selected report as active
      this.$set(this.isSecondaryButtonSelected, reportKey, true);

      const roleRoute = {
        AffiliateAdmin: "affiliateadminreportparameter",
        Pharmacy: "pharmacyreportparameter",
        Support: "supportreportparameter",
        Physician: "physicianreportparameter",
      };

      const routeName = roleRoute[this.Role];

      // Navigate to the new route
      this.$router.push({
        name: routeName,
        params: { reportId: reportKey, reportName: reportValue },
      });
      this.setSidebarAdmin(false);
    },

    async GetReportNames() {
      try {
        const roleEndpointMap = {
          AffiliateAdmin: "/api/affiliateadmin/GetReportNames",
          Pharmacy: "/api/pharmacy/GetReportNames",
          Support: "/api/support/GetReportNames",
          Physician: "/api/physician/GetReportNames",
        };

        const endpoint = roleEndpointMap[this.Role];

        const reportNames = await this.$store.dispatch("GetReportNames", endpoint);
        this.reportNames = reportNames;
      } catch (error) {
        console.error("Error fetching report names:", error);
      }
    },

    closeSidebarMenu() {
      this.setSidebarAdmin(false);
    },
  },
};
