
import { QuestionTypeEnum } from "@/interfaces/QuestionTypeEnum";
import { Question } from "@/interfaces/Question";
import { unsanitize } from "@/utils/htmlUtils";
import Vue from "vue";

export default Vue.extend({
  name: "question-free-text",
  props: {
    value: Object,
    showerror: Boolean,
    readonly: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      question: null as Question | null,
      freeText: "" as string,
      questionTypeEnum: QuestionTypeEnum,
    };
  },
  created() {
    this.importData(this.value);
  },
  computed: {
    getHTML() {
      this.question!.selectedAnswers = [
        {
          answerID: 1,
          followUpQuestionID: 0,
          order: 1,
          answerText: "",
          deny: false,
          alertText: "",
          hardStop: false,
          chooseProductID: null,
          requirePhysicianTier: null,
          image: "",
          calculated: false,
        },
      ];
      return unsanitize(this.question!.questionText);
    },
  },
  watch: {
    value(newVal) {
      this.importData(newVal);
    },
    freeText(newVal) {
      if (this.question == null) return;
      this.question.selectedAnswers = [{ answerText: newVal, followUpQuestionID: 0, order: 0, answerID: 0 }];
    },
  },
  methods: {
    importData(newVal: Question) {
      this.question = newVal;
      this.freeText = this.question.selectedAnswers && this.question.selectedAnswers.length > 0 && this.question.selectedAnswers[0] !== null ? this.question.selectedAnswers[0].answerText : "";
    },
  },
});
