
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import Vue from "vue";

export default Vue.extend({
  name: "photos-update-complete",
  methods: {
    next_click() {
      this.$emit("saveandnavigate", "+");
    },
  },
  components: { QuestWrapperComponent },
});
