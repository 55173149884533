
import mixins from "vue-typed-mixins";
import CustomValidation from "@/mixins/CustomValidation";
import common from "@/mixins/Common";
import { mapGetters, mapMutations } from "vuex";
import store from "@/store";
import Calendar from "primevue/calendar";
import "primevue/resources/themes/md-light-deeppurple/theme.css";
import "primevue/resources/primevue.min.css";
import InputText from "primevue/inputtext";
import * as echarts from "echarts";
import { ECharts } from "echarts/core";
import { init } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { WeightLog } from "@/interfaces/WeightLog";
import { VitalTypeIDEnum } from "@/interfaces/VitalTypeIDEnum";
import { screenview } from "@/interfaces/WeightTrackerScreenViewEnum";

// Register necessary components
echarts.use([CanvasRenderer]);
import Slider from "primevue/slider";

export default mixins(CustomValidation, common).extend({
  name: "weight-tracker",
  props: {
    showFull: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data(props) {
    return {
      view: screenview.trackerDash,
      startingWeight: 0,
      goalWeight: 0,
      currentWeight: 0 as number,
      newWeight: 0 as number | null,
      weightProgress: null as number | null,
      weightDifference: null as number | null,
      newWeightDate: new Date(),
      chart: null as ECharts | null,
      weightLogs: [] as WeightLog[],
      rawWeightData: [],
      heightInInches: 0,
      savedBMI: 0,
      mostRecentWeightLog: null,
      WeightTrackerDataObject: null,
      NewGoalWeight: 0,
      allowGoalWeightUpdate: false,
      isShowFull: props.showFull,
      reRenderTrigger: true,
      affirmationText: "",
      affirmationPounds: 0,
      weeksPassed: 0,
      queuedAffirmationToRemove: "",
      affiliateHidesBMI: false,
      useWeightLogs: false,
    };
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      FullPatientWidgetData: "getFullPatientWidgetData",
    }),
    invalidGoalWeight() {
      if (this.NewGoalWeight > 100) {
        if (this.NewGoalWeight === this.currentWeight || this.NewGoalWeight === this.startingWeight) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    getEarliestWeight() {
      if (this.weightLogs.length === 0) {
        return this.startingWeight;
      }

      const earliestLog = this.weightLogs.reduce((earliest, log) => {
        return new Date(log.date) < new Date(earliest.date) ? log : earliest;
      });

      return earliestLog.weight;
    },
    getPoundsLostSinceStart() {
      this.affirmationPounds = this.getEarliestWeight - this.currentWeight;
      return Math.abs(this.getEarliestWeight - this.currentWeight);
    },
    getProgressInPoundsGainOrLost() {
      this.affirmationPounds = this.getEarliestWeight - this.currentWeight;
      if (this.affirmationPounds <= 0) {
        return `Gained ${Math.abs(this.affirmationPounds)} pounds`;
      } else {
        return `Lost ${Math.abs(this.affirmationPounds)} pounds!`;
      }
    },
    getMostRecentWeightLogDate() {
      if (this.useWeightLogs) {
        return this.weightLogs.slice().sort((a, b) => new Date(b.date) - new Date(a.date))[0].date;
      } else {
        if (this.mostRecentWeightLog && this.mostRecentWeightLog.createdDate) {
          return this.mostRecentWeightLog.createdDate;
        } else {
          return new Date();
        }
      }
    },
    tooltipOptions() {
      return this.isShowFull ? { title: "Update Goal Weight" } : {};
    },
    bmiCategory() {
      if (this.savedBMI === null) {
        return null;
      } else if (this.savedBMI < 18.5) {
        return "Underweight";
      } else if (this.savedBMI >= 18.5 && this.savedBMI < 24.9) {
        return "Normal";
      } else if (this.savedBMI >= 25 && this.savedBMI < 29.9) {
        return "Overweight";
      } else {
        return "Obese";
      }
    },
    currentBMICategory() {
      if (this.savedBMI === null) {
        return null;
      } else if (this.savedBMI < 18.5) {
        return `15%`;
      } else if (this.savedBMI >= 18.5 && this.savedBMI < 24.9) {
        return `40%`;
      } else if (this.savedBMI >= 25 && this.savedBMI < 29.9) {
        return `60%`;
      } else {
        return `100%`;
      }
    },
    progressBar() {
      if (this.currentWeight > this.goalWeight && this.currentWeight > this.startingWeight) {
        return "0%";
      }
      if (this.currentWeight <= this.goalWeight) {
        return "100%";
      } else {
        const weightLost = this.startingWeight - this.currentWeight;
        const percentageLost = (weightLost / (this.startingWeight - this.goalWeight)) * 100;
        const roundedP = Math.min(Math.floor(percentageLost), 100); // Cap at 100%
        return `${roundedP}%`;
      }
    },
    healthyWeightRange() {
      if (this.heightInInches > 0) {
        const minBMI = 18.5;
        const maxBMI = 24.9;

        const minWeight = (minBMI * (this.heightInInches * this.heightInInches)) / 703;
        const maxWeight = (maxBMI * (this.heightInInches * this.heightInInches)) / 703;

        return {
          min: minWeight.toFixed(2),
          max: maxWeight.toFixed(2),
        };
      } else {
        this.heightInInches = 66;
        const minBMI = 18.5;
        const maxBMI = 24.9;

        const minWeight = (minBMI * (this.heightInInches * this.heightInInches)) / 703;
        const maxWeight = (maxBMI * (this.heightInInches * this.heightInInches)) / 703;

        return {
          min: minWeight.toFixed(2),
          max: maxWeight.toFixed(2),
        };
      }
    },
    getMostRecentWeightEntry() {
      if (this.weightLogs.length > 0) {
        return this.weightLogs.reduce((mostRecent, current) => {
          return new Date(current.date) > new Date(mostRecent.date) ? current : mostRecent;
        }, this.weightLogs[0] || {});
      } else {
        this.startingWeight = this.currentWeight;
        const x = {
          weight: this.currentWeight,
        };
        return this.currentWeight;
      }
    },
    sortedWeightLogs(): WeightLog[] {
      return [...this.weightLogs].sort((a, b) => b.date.getTime() - a.date.getTime());
    },
  },
  async mounted() {
    this.processData();
    this.weightProgressCalc();
    this.initChart();
  },
  methods: {
    calculatePoundsLostSinceStart() {
      if (this.affirmationPounds && this.getEarliestWeight && this.currentWeight && this.weeksPassed) {
        this.affirmationPounds = (this.getEarliestWeight - this.currentWeight) / this.weeksPassed;
      }
    },
    calculateWeeksPassed() {
      if (this.rawWeightData) {
        const weeksPassedIndex = this.rawWeightData.filter((element) => element.vitalTypeID === VitalTypeIDEnum.CalculateWeeksPassedForWeightTracker);

        if (weeksPassedIndex.length > 0) {
          let value1 = parseFloat(weeksPassedIndex[0].value1);

          if (isNaN(value1) || value1 < 1) {
            this.weeksPassed = 1;
          } else {
            this.weeksPassed = value1;
          }

          this.rawWeightData = this.rawWeightData.filter((element) => element.vitalTypeID !== VitalTypeIDEnum.CalculateWeeksPassedForWeightTracker);
        }
      }
    },
    calculateAffirmation() {
      if (this.Content.patientWidgets.weightTracker.WeightTrackingWidgetMotivationLost && this.Content.patientWidgets.weightTracker.WeightTrackingWidgetMotivationGained && this.Content.patientWidgets.weightTracker.WeightTrackingWidgetMotivationSame && this.currentWeight && this.newWeight) {
        const WeightTrackingWidgetMotivationLost = this.Content.patientWidgets.weightTracker.WeightTrackingWidgetMotivationLost;
        const WeightTrackingWidgetMotivationGained = this.Content.patientWidgets.weightTracker.WeightTrackingWidgetMotivationGained;
        const WeightTrackingWidgetMotivationSame = this.Content.patientWidgets.weightTracker.WeightTrackingWidgetMotivationSame;

        let updatedWeightTrackingWidgetMotivationLost;
        let updatedWeightTrackingWidgetMotivationGained;
        let updatedWeightTrackingWidgetMotivationSame;
        let weightDiffTruthy;

        if (this.lastAffirmationUsed !== "") {
          // const alreadyBeenUsedString = this.removeParameterization(this.mostRecentWeightLog.value2); // uncomment for parameterized strings
          const alreadyBeenUsedString = this.lastAffirmationUsed;

          const recentLogEntry = this.weightLogs.reduce((mostRecent, current) => {
            return new Date(current.date) > new Date(mostRecent.date) ? current : mostRecent;
          }, this.weightLogs[0] || {});

          if (this.currentWeight - this.newWeight === 0) {
            weightDiffTruthy = 0;
          }

          if (this.currentWeight - this.newWeight > 0) {
            weightDiffTruthy = 1; // lost weight
          }

          if (this.currentWeight - this.newWeight < 0) {
            weightDiffTruthy = 2; // gained weight
          }

          switch (weightDiffTruthy) {
            case 2:
              updatedWeightTrackingWidgetMotivationGained = WeightTrackingWidgetMotivationGained.filter((element) => {
                return element !== alreadyBeenUsedString;
                // return this.removeParameterization(element) !== alreadyBeenUsedString;
              });
              WeightTrackingWidgetMotivationGained.length = 0;
              WeightTrackingWidgetMotivationGained.push(...updatedWeightTrackingWidgetMotivationGained);
              this.randomlySelectAffirmation(updatedWeightTrackingWidgetMotivationGained);
              break;

            case 1:
              updatedWeightTrackingWidgetMotivationLost = WeightTrackingWidgetMotivationLost.filter((element) => {
                return element !== alreadyBeenUsedString;
                // return this.removeParameterization(element) !== alreadyBeenUsedString;
              });
              WeightTrackingWidgetMotivationLost.length = 0;
              WeightTrackingWidgetMotivationLost.push(...updatedWeightTrackingWidgetMotivationLost);
              this.randomlySelectAffirmation(updatedWeightTrackingWidgetMotivationLost);
              break;

            case 0:
              updatedWeightTrackingWidgetMotivationSame = WeightTrackingWidgetMotivationSame.filter((element) => {
                return element !== alreadyBeenUsedString;
                // return this.removeParameterization(element) !== alreadyBeenUsedString;
              });
              WeightTrackingWidgetMotivationSame.length = 0;
              WeightTrackingWidgetMotivationSame.push(...updatedWeightTrackingWidgetMotivationSame);
              this.randomlySelectAffirmation(WeightTrackingWidgetMotivationSame);
              break;

            default:
              console.error("not a positive, negative, or zero weight difference. Possible NaN in method calculateAffirmation()");
              break;
          }
        }
      }
    },
    // Uncomment this when we start using parameterized strings
    // removeParameterization(affirmationString: string) {
    //   return affirmationString.replace(/<span class="font-weight-bold">.*?<\/span>/, '<span class="font-weight-bold">PLACEHOLDER</span>');
    // },
    randomlySelectAffirmation(selectedAffirmationArray: Array<string>) {
      const RandomIndex = Math.floor(Math.random() * selectedAffirmationArray.length);
      this.queuedAffirmationToRemove = selectedAffirmationArray[RandomIndex];
      this.affirmationText = selectedAffirmationArray[RandomIndex];
    },
    processData() {
      if (this.Content.patientWidgets && this.Content.patientWidgets.weightTracker && this.Content.patientWidgets.weightTracker.WeightTrackingWidgetHideBMI) {
        this.affiliateHidesBMI = this.Content.patientWidgets.weightTracker.WeightTrackingWidgetHideBMI;
      }

      if (this.FullPatientWidgetData && this.FullPatientWidgetData.wtData && this.FullPatientWidgetData.wtData.weightTrackerObject && this.FullPatientWidgetData.wtData.weightLogEntries && this.FullPatientWidgetData.wtData.weightLogEntries.length > 0) {
        this.WeightTrackerDataObject = this.FullPatientWidgetData.wtData.weightTrackerObject;

        this.goalWeight = JSON.parse(this.WeightTrackerDataObject.value).goalWeight;
        this.lastAffirmationUsed = JSON.parse(this.WeightTrackerDataObject.value).lastAffirmationUsed;
        this.rawWeightData = this.FullPatientWidgetData.wtData.weightLogEntries;
        this.calculateWeeksPassed();

        const weightEntries = this.rawWeightData.filter((entry) => entry.vitalTypeID === VitalTypeIDEnum.Weight).sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());
        this.weightLogs = weightEntries.map((entry, index) => {
          const weight = parseFloat(entry.value1);
          const date = new Date(entry.createdDate);
          const previousWeightDifference = index > 0 ? weight - parseFloat(weightEntries[index - 1].value1) : null;
          return {
            weight,
            date,
            previousWeightDifference,
          };
        });

        const heightIndex = this.rawWeightData.filter((element) => element.vitalTypeID === VitalTypeIDEnum.Height);
        const bmiIndex = this.rawWeightData.filter((element) => element.vitalTypeID === VitalTypeIDEnum.BMI).sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
        const currentWeightIndex = this.rawWeightData.filter((element) => element.vitalTypeID === VitalTypeIDEnum.Weight).sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
        const startingWeightIndex = this.rawWeightData.filter((element) => element.vitalTypeID === VitalTypeIDEnum.Weight).sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());

        if (heightIndex.length > 0 && bmiIndex.length > 0 && currentWeightIndex.length > 0 && startingWeightIndex.length > 0) {
          this.heightInInches = this.convertHeightStringToInches(heightIndex[0].value1);
          this.savedBMI = bmiIndex[0].value1;
          this.currentWeight = currentWeightIndex[0].value1;
          this.mostRecentWeightLog = currentWeightIndex[0];
          this.startingWeight = startingWeightIndex[0].value1;
          this.newWeight = this.currentWeight;
        }

        this.calculatePoundsLostSinceStart();
        this.calculateAffirmation();
      }
    },
    convertHeightStringToInches(rawHeight: string) {
      const regex = /(\d+)'(\d+)"/;
      const match = rawHeight.match(regex);

      if (match) {
        const feet = parseInt(match[1], 10);
        const inches = parseInt(match[2], 10);
        const totalInches = feet * 12 + inches;

        return totalInches;
      }
    },
    calculateBMI(weightLogEntry: any) {
      if (this.heightInInches && weightLogEntry.weight) {
        const BMI = (weightLogEntry.weight * 703) / (this.heightInInches * this.heightInInches);
        return BMI.toFixed(2);
      }
    },
    toggleGoalWeightUpdate() {
      if (this.isShowFull) {
        this.goalWeight = 0;
      }
    },
    async updateGoalWeight() {
      this.allowGoalWeightUpdate = !this.allowGoalWeightUpdate;
      const x = JSON.parse(this.WeightTrackerDataObject.value);
      x.goalWeight = this.NewGoalWeight;
      const y = JSON.stringify(x);
      this.WeightTrackerDataObject.value = y;
      await store.dispatch("SetGoalWeight", this.WeightTrackerDataObject);
      this.goalWeight = this.NewGoalWeight;
      this.$emit("triggerReRender");
    },
    openUpdateWeight() {
      if (this.isShowFull) {
        this.view = screenview.updateWeight;
      }
    },
    calculateUpdateAffirmation() {
      if (this.Content.patientWidgets.weightTracker.WeightTrackingWidgetMotivationLost && this.Content.patientWidgets.weightTracker.WeightTrackingWidgetMotivationGained && this.Content.patientWidgets.weightTracker.WeightTrackingWidgetMotivationSame && this.currentWeight && this.newWeight) {
        const WeightTrackingWidgetMotivationLost = this.Content.patientWidgets.weightTracker.WeightTrackingWidgetMotivationLost;
        const WeightTrackingWidgetMotivationGained = this.Content.patientWidgets.weightTracker.WeightTrackingWidgetMotivationGained;
        const WeightTrackingWidgetMotivationSame = this.Content.patientWidgets.weightTracker.WeightTrackingWidgetMotivationSame;

        let weightDiffTruthy;

        if (this.currentWeight - this.newWeight === 0) {
          weightDiffTruthy = 0;
        }

        if (this.currentWeight - this.newWeight > 0) {
          weightDiffTruthy = 1; // lost weight
        }

        if (this.currentWeight - this.newWeight < 0) {
          weightDiffTruthy = 2; // gained weight
        }

        switch (weightDiffTruthy) {
          case 2:
            this.randomlySelectAffirmation(WeightTrackingWidgetMotivationGained);
            break;

          case 1:
            this.randomlySelectAffirmation(WeightTrackingWidgetMotivationLost);
            break;

          case 0:
            this.randomlySelectAffirmation(WeightTrackingWidgetMotivationSame);
            break;

          default:
            console.error("not a positive, negative, or zero weight difference. Possible NaN in method calculateAffirmation()");
            break;
        }
      }
    },
    async updateWeight() {
      this.calculateUpdateAffirmation();
      if (this.newWeight && !isNaN(this.newWeight) && this.weightLogs && this.WeightTrackerDataObject.value && this.queuedAffirmationToRemove) {
        let difference: number | null = null;
        if (this.weightLogs.length > 0) {
          const lastLog = this.weightLogs[this.weightLogs.length - 1];
          difference = parseFloat((this.newWeight - lastLog.weight).toFixed(1));
        }
        const NewObj = {
          weight: this.newWeight,
          date: new Date(this.newWeightDate),
          previousWeightDifference: difference,
        };

        this.weightLogs.push(NewObj);

        const x = JSON.parse(this.WeightTrackerDataObject.value);
        x.lastAffirmationUsed = this.queuedAffirmationToRemove;
        const y = JSON.stringify(x);
        this.WeightTrackerDataObject.value = y;

        const outgoingWeightEntry = {
          weight: this.newWeight.toString(),
          date: new Date(this.newWeightDate),
          newBMI: this.calculateBMI(NewObj).toString(),
          WeightTrackerObject: this.WeightTrackerDataObject,
        };

        try {
          const result = await store.dispatch("AddWeightLogEntry", outgoingWeightEntry);
          if (result) {
            this.useWeightLogs = true;
            this.$bvToast.toast("Added new entry!", { title: "Success", variant: "success", autoHideDelay: 3000, appendToast: true });
            this.savedBMI = this.calculateBMI(NewObj);
            if (this.newWeight) {
              this.currentWeight = this.newWeight;
            }
            this.weightProgressCalc();

            this.view = screenview.motivation;
            this.calculatePoundsLostSinceStart();
          } else {
            this.$bvToast.toast("Unable to add new entry, please contact support!", { title: "Uh-oh", variant: "wanrings", autoHideDelay: 3000, appendToast: true });
          }
        } catch (error) {
          this.$bvToast.toast(`Unable to add new entry due to ${error}, please contact support!`, { title: "Uh-oh", variant: "wanrings", autoHideDelay: 3000, appendToast: true });
        }
      }
    },
    confirmMotivation() {
      this.view = screenview.trackerDash;
      this.$nextTick(() => {
        this.initChart();
      });

      this.$emit("triggerReRender");
    },
    weightProgressCalc() {
      if (this.goalWeight && this.currentWeight && this.startingWeight) {
        if (this.goalWeight !== 0) {
          let currentWeight = this.currentWeight;
          let startingWeight = this.startingWeight;
          let goalWeight = this.goalWeight;

          let weightProgress = ((startingWeight - currentWeight) / (startingWeight - goalWeight)) * 100;

          this.weightDifference = parseFloat((startingWeight - currentWeight).toFixed(2));

          this.weightProgress = weightProgress;
        } else {
          let currentWeight = 100;
          let startingWeight = 100;
          let goalWeight = 100;

          let weightProgress = ((startingWeight - currentWeight) / (startingWeight - goalWeight)) * 100;

          this.weightDifference = parseFloat((startingWeight - currentWeight).toFixed(2));

          this.weightProgress = weightProgress;
        }
      }
    },
    initChart() {
      this.chart = echarts.init(this.$refs.weightChart as HTMLElement);

      const dates = this.weightLogs.map((log) => log.date.toLocaleDateString());
      const weights = this.weightLogs.map((log) => log.weight);

      const option = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: dates,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: weights,
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#67216A",
              width: 4, // Line thickness
            },
            itemStyle: {
              color: "#000", // Circle color
            },
          },
        ],
      };

      this.chart.setOption(option);
    },
  },
  components: {
    Calendar,
    InputText,
    Slider,
  },
});
