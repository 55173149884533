
import Vue from "vue";
import { PropType } from "vue";
import { Question } from "@/interfaces/Question";
import { Answer } from "@/interfaces/Answer";
import Dropdown from "primevue/dropdown";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "question-calculator",
  props: {
    value: {} as PropType<Question>,
    showerror: Boolean,
    readonly: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    Dropdown,
  },
  data() {
    return {
      question: {} as Question | null,
      useranswers: [] as Answer[],
      bmi: 0 as number | 0,
      bmiq: 0 as number | 0,
      recompute: 0,
      heightft: null as string | null,
      heightinch: null as string | null,
      bmipbar: null as string | null,
      heightInFeet: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      heightInInches: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    };
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
    }),
    under(): number {
      this.recompute;
      const height = this.calcHeight();
      if (!height) {
        return 0;
      }
      return Math.floor((18.5 / 703) * (height * height));
    },
    normalmin(): number {
      this.recompute;
      const height = this.calcHeight();
      if (!height) {
        return 0;
      }
      return Math.floor((18.5 / 703) * (height * height));
    },
    normalmax(): number {
      this.recompute;
      const height = this.calcHeight();
      if (!height) {
        return 0;
      }
      return Math.floor((24.9 / 703) * (height * height));
    },
    overmin(): number {
      this.recompute;
      const height = this.calcHeight();
      if (!height) {
        return 0;
      }
      return Math.floor((25 / 703) * (height * height));
    },
    overmax(): number {
      this.recompute;
      const height = this.calcHeight();
      if (!height) {
        return 0;
      }
      return parseInt(((29.9 / 703) * (height * height)).toFixed(0));
    },
    obese(): number {
      this.recompute;
      const height = this.calcHeight();
      if (!height) {
        return 0;
      }
      return parseInt(((29.9 / 703) * (height * height)).toFixed(0));
    },
  },
  watch: {
    heightft(nv: string) {
      this.heightft = nv;
    },
    heightinch(nv: string) {
      if (parseInt(nv) > 11) {
        this.heightinch = "11";
      } else if (parseInt(nv) < 0) {
        this.heightinch = "0";
      } else {
        this.heightinch = nv;
      }
    },
    value(nv: Question) {
      this.importData(nv);
    },
  },
  mounted() {
    this.importData(this.value);
  },
  methods: {
    importData(q: Question) {
      if (!q) {
        return;
      }
      this.question = q;
      this.useranswers = [];

      if (this.question.answers) {
        for (var i = 0; i < this.question.answers.length; i++) {
          if (i >= this.useranswers.length) {
            this.useranswers.push({} as Answer);
            this.useranswers[i].answerText = "";
          }
          this.useranswers[i].answerID = this.question.answers[i].answerID;
          if (this.question.selectedAnswers.length > i && this.question.selectedAnswers[i]) {
            this.useranswers[i].answerText = this.question.selectedAnswers[i].answerText.split(": ")[1];
            if (isNaN(parseInt(this.useranswers[i].answerText))) {
              this.useranswers[i].answerText = "";
            }
          } else {
            this.useranswers[i].answerText = "";
          }
        }
        const h = this.getAnswerIntValue("height");
        if (h > 0) {
          this.heightft = Math.floor(h / 12).toString();
          this.heightinch = (h % 12).toString();
        }
        this.refresh();
        this.save();
      }
    },
    inputChanged(a: Answer) {
      this.recompute++;
      console.log("Height Feet: ", this.heightft);
      console.log("Height Inch: ", this.heightinch);
      this.refresh();
      this.save();
    },
    refresh() {
      let height = this.calcHeight();
      if (!height) {
        return;
      }
      const h = this.getAnswer("height");
      if (h) h.answerText = height <= 0 ? "" : height.toString();

      const w = this.getAnswer("weight");
      let weight = this.getAnswerIntValue("Weight");
      if (w) w.answerText = weight <= 0 ? "" : weight.toString();

      if (weight <= 0 || height <= 0) {
        this.bmi = 0;
        this.bmiq = 0;
        return;
      } else if (weight < this.under) {
        this.bmiq = 1;
      } else if (weight <= this.normalmax) {
        this.bmiq = 2;
      } else if (weight <= this.overmax) {
        this.bmiq = 3;
      } else if (weight > this.obese) {
        this.bmiq = 4;
      }
      //pbar
      var percent = 0;
      if (weight < this.under) {
        percent = (weight / this.under / 4) * 100;
      } else if (weight <= this.normalmax) {
        percent = ((weight - this.normalmin) / (this.normalmax - this.normalmin) / 4) * 100 + 25;
      } else if (weight <= this.overmax) {
        percent = ((weight - this.overmin) / (this.overmax - this.overmin) / 4) * 100 + 50;
      } else if (weight > this.obese) {
        percent = ((weight - this.obese) / (this.obese + 34 - this.obese) / 4) * 100 + 75;
        percent = Math.min(percent, 100);
      }
      this.bmipbar = Math.floor(percent) + "%";

      //update bmi
      this.bmi = this.calcBMI();
      var an = this.getAnswer("BMI");
      if (an) {
        an.answerText = this.bmi.toString();
      }
    },
    calcHeight() {
      if (!this.heightft || !this.heightinch) {
        return null;
      }
      return parseInt(this.heightft) * 12 + parseInt(this.heightinch);
    },
    calcBMI(): number {
      const weight: number = this.getAnswerIntValue("Weight");
      if (!weight) {
        return 0;
      }
      const height = this.calcHeight();
      if (!height) {
        return 0;
      }
      const b = parseFloat(((weight / (height * height)) * 703).toFixed(1));
      return b;
    },

    save() {
      if (!this.question) {
        return;
      }
      if (!this.question.selectedAnswers) {
        this.question.selectedAnswers = [];
      }
      if (this.question.answers) {
        for (var i = 0; i < this.question.answers.length; i++) {
          if (i >= this.question?.selectedAnswers.length) {
            this.question.selectedAnswers.push({} as Answer);
          }
          this.question.selectedAnswers[i].answerID = this.question.answers[i].answerID;
          //have empty answer -> skip save && stop user
          if (this.useranswers[i].answerText == "") {
            this.question.selectedAnswers = [];
            return;
          }
          this.question.selectedAnswers[i].answerText = this.question.answers[i].answerText + ": " + this.useranswers[i].answerText;
          this.question.selectedAnswers[i].calculated = this.question.answers[i].calculated;
          this.question.selectedAnswers[i].deny = this.question.answers[i].deny;
        }
      }
    },
    getAnswer(val: string) {
      if (!this.question) {
        return null;
      }
      const ha = this.question.answers.findIndex((e) => e.answerText.toLowerCase() == val.toLowerCase());
      if (ha < 0) {
        return null;
      }
      const h = this.useranswers.find((e) => e.answerID == this.question?.answers[ha].answerID);
      if (!h) {
        return null;
      }
      return h;
    },
    getAnswerIntValue(val: string): number {
      if (!this.question) {
        return 0;
      }
      const ha = this.question.answers.findIndex((e) => e.answerText.toLowerCase() == val.toLowerCase());
      if (ha < 0) {
        return 0;
      }
      const h = this.useranswers.find((e) => e.answerID == this.question?.answers[ha].answerID);
      if (!h || h.answerText == "") {
        return 0;
      }
      return parseInt(h.answerText);
    },
  },
});
