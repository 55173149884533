
import mixins from "vue-typed-mixins";
import CustomValidation from "../../mixins/CustomValidation";
import { BModal } from "bootstrap-vue";
import PromoPricing from "@/mixins/PromoPricing";
import common from "../../mixins/Common";
import { mapGetters, mapMutations } from "vuex";
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import QuestionSingleChoiceVueComponentVue from "@/components/QuestionSingleChoiceVueComponent.vue";
import QuestionFreeTextVueComponentVue from "@/components/QuestionFreeTextVueComponent.vue";
import QuestionMultipleFreeTextVueComponentVue from "@/components/QuestionMultipleFreeTextVueComponent.vue";
import QuestionImageVueComponentVue from "@/components/QuestionImageVueComponent.vue";
import { number, object, string, boolean } from "yup";
import swal from "sweetalert";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { PatientViewModel } from "@/interfaces/PatientViewModel";
import UpsellVueComponent from "@/components/UpsellVueComponent.vue";

// Import jQuery
import $ from "jquery";

// Import Popper.js
import Popper from "popper.js";

// Import Bootstrap JS
import "bootstrap/dist/js/bootstrap.min.js";
import { PromoViewModel } from "@/interfaces/PromoViewModel";
import { ProductRx } from "@/interfaces/ProductRx";
import { QuestionnaireTypeProductViewModel } from "@/interfaces/QuestionnaireTypeProductViewModel";
import { AffiliateProductRx } from "@/interfaces/AffiliateProductRx";
import { QuestionnaireProductRx } from "@/interfaces/QuestionnaireProductRx";
import { ProductRxSubscriptionFrequencyViewModel } from "@/interfaces/ProductRxSubscriptionFrequencyViewModel";
import store from "@/store";
import QuestionInformationVueComponent from "@/components/QuestionInformationVueComponent.vue";

export interface ICollectJS {
  startPaymentRequest: (arg0: any) => void;
  configure: (arg0: any) => void;
}

export interface VueOptions {
  filters: any;
}

export default mixins(CustomValidation, common, PromoPricing).extend({
  name: "CheckoutView",
  mixins: [CustomValidation, common, PromoPricing],
  data() {
    return {
      showUpsellModal: false as boolean,
      password: "" as string,
      confirmPassword: "" as string,
      firstName: "" as string,
      lastName: "" as string,
      iAgree: false as boolean,
      address1: null as string | null,
      address2: null as string | null,
      city: null as string | null,
      state: null as string | null,
      zip: null as string | null,
      homeAddress1: null as string | null,
      homeAddress2: null as string | null,
      homeCity: null as string | null,
      homeState: null as string | null,
      homeZip: null as string | null,
      shippingIsSame: true,
      userAllowsChargeAndFillUponApproval: false as boolean,
      authToken: null as string | null,
      secCodeError: null as string | null,
      expDateError: null as string | null,
      cardNumError: null as string | null,
      keepVaultID: false as boolean,
      ccInfoInvalid: false,
      subscriptionSavePaymentText: "Your payment details will be saved.",
      selectedShippingMethod: "standard",
      isCartPopupOpen: false as boolean,
      checkColor: "rgba(128, 128, 128, 0.541)",
      freqs: [] as ProductRxSubscriptionFrequencyViewModel[],
      patientStatesMustMatch: false as boolean,
      schema: object().shape({
        address1: string().typeError("Address is required.").required("Address is required."),
        city: string().typeError("City is required.").required("City is required."),
        state: string().typeError("State is required.").required("State is required."),
        zip: number().integer().typeError("Zip must be a number and is required.").max(99999, "Zip must be a number and is required.").required("Zip must be a number and is required."),
        homeAddress1: string().typeError("Address is required.").required("Address is required."),
        homeCity: string().typeError("City is required.").required("City is required."),
        homeState: string().typeError("State is required.").required("State is required."),
        homeZip: number().integer().typeError("Zip must be a number and is required.").max(99999, "Zip must be a number and is required.").required("Zip must be a number and is required."),
        /*password: string().typeError("Password is required.").required("Password is required."),
        confirmPassword: string().typeError("Password is required.").required("Password is required."),
        firstName: string().typeError("First Name is required.").required("First Name is required."),
        lastName: string().typeError("Last Name is required.").required("Last Name is required."),
        iAgree: boolean().typeError("You must agree to the terms of use privacy policy and consent to remote health.").oneOf([true], "You must agree to the terms of use privacy policy and consent to remote health.").required("You must agree to the terms of use privacy policy and consent to remote health."),*/
      }),
    };
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      Theme: "getTheme",
      IsCollectJSLoaded: "getIsCollectJSLoaded",
      Loading: "getLoading",
      QuestionnaireTypeProducts: "getQuestionnaireTypeProducts",
      QuestType: "getCurrentQuestType",
    }),
    noError(): boolean {
      if (!(this.getError("address1") || this.getError("address2") || this.getError("city") || this.getError("state") || this.getError("zip"))) {
        return true;
      } else {
        return false;
      }
    },
    questionnaireTypeProduct(): QuestionnaireTypeProductViewModel {
      return this.QuestionnaireTypeProducts.filter((x: QuestionnaireTypeProductViewModel) => x.id == this.q.quest.questionnaireTypeID)[0];
    },
    expeditedShippingBtnTxt(): string | undefined {
      if (!this.$options) return;
      if (this.isExpeditedShipping) return "Remove";
      else return "Add +" + (this.$options as VueOptions).filters.toCurrencyNoChange(this.q.expeditedShippingFee - this.q.standardShippingFee);
    },
    nextText(): string {
      return "CONTINUE";
    },
    paymentInfoIsOptional(): boolean {
      if (this.q.chargeAllUpFront) return false;
      if (this.isRefill || this.isPayment) return false;
      if (this.adjustedDoctorConsultfee > 0) return false;
      if (this.userAllowsChargeAndFillUponApproval) return false;
      if (this.isSubscription) return false;
      return true;
    },
    noCurrentCharge(): boolean {
      //console.log("this.adjustedDoctorConsultfee");
      //console.log(this.adjustedDoctorConsultfee);
      if (this.q.chargeAllUpFront) return false;
      if (this.isRefill || this.isPayment) return false;
      if (this.adjustedDoctorConsultfee > 0) return false;
      if (this.isSubscription) return false;
      if (this.isSubChargePayment) return false;
      return true;
    },
    stepType(): string {
      return this.isRefill ? "refill" : this.isPayment ? "payment" : this.isModify ? "modify" : this.isSubChargePayment ? "subchargepayment" : "new";
    },
  },
  async mounted() {
    this.setLoading(true);
    this.patientStatesMustMatch = this.q.patientStatesMustMatch;
    this.importData(this.q.quest);
    this.importPatient(this.q.patient);
    this.setupCollect();
    // await store.dispatch("GetPromoByCode", { t: this.q.quest.questionnaireTypeID, code: this.q.quest.promoCode, notInitialQuest: false });
    //this.mixinAdjustedDoctorConsultfee(this.isPayment, this.isRefill, this.q, this.mixinGetPromoProd(this.q.everyonePromo, this.qProductRx.id, this.qProductRx.isSubscription), this.mixinGetPromoProd(codePromo, this.qProductRx.id, this.qProductRx.isSubscription));
    window.setInterval(() => {
      this.ccInfoInvalidChecker();
    }, 1000);
    this.setupValidation(this.schema);
    this.updateCheckColor();
  },
  watch: {
    userAllowsChargeAndFillUponApproval(newVal, oldVal) {
      if (newVal == false && oldVal == true) {
        swal("", "Keeping this checked will prevent you from having to sign back in and pay for your prescription after doctor approval.", "warning");
      }
    },
    q(newVal: QuestViewModel) {
      this.importQuest(newVal.quest);
      this.importPatient(newVal.patient);
    },
    address1(newVal) {
      this.homeAddress1 = this.shippingIsSame ? newVal : this.homeAddress1;
    },
    address2(newVal) {
      this.homeAddress2 = this.shippingIsSame ? newVal : this.homeAddress2;
    },
    city(newVal) {
      this.homeCity = this.shippingIsSame ? newVal : this.homeCity;
    },
    state(newVal) {
      //console.log(`newVal ${newVal}`);
      //console.log(`homeState ${this.homeState}`);
      //console.log(`state ${this.state}`);
      if (this.shippingIsSame || !this.patientStatesMustMatch) {
        this.checkSupportedState(this.state, (supported) => {
          if (!supported) {
            this.homeState = "";
            this.shippingIsSame = false;
            swal("", "Your Home State is not available for shipping.", "warning");
          } else {
            this.homeState = newVal;
          }
        });
      }
    },
    zip(newVal) {
      this.homeZip = this.shippingIsSame ? newVal : this.homeZip;
      if (this.homeZip && this.homeZip?.length >= 5 && !this.Loading) {
        this.paymentClick();
      }
    },
    iAgree(newVal) {
      if (newVal) this.shippingClick();
    },
    selectedShippingMethod(newValue) {
      if (newValue === "overnight") {
        this.isExpeditedShipping = true;
      } else {
        this.isExpeditedShipping = false;
      }
    },
    shippingIsSame(newVal) {
      this.handleShippingIsSameClick(newVal);
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setQuestionnaire: "setQuestionnaire",
    }),
    changeFreq(selectedFrequency: ProductRxSubscriptionFrequencyViewModel) {
      var q = Object.assign({}, this.q);
      q.quest = this.getQuestData();
      q.patient = this.getPatientData();
      var qProductRx = {} as QuestionnaireProductRx;
      if (q.quest.qProductRxs && q.quest.qProductRxs.length > 0) {
        var qprs = q.quest.qProductRxs.filter((x) => x.productRxGroupID && x.productRxGroupID > 0);
        if (qprs && qprs.length > 0) {
          qProductRx = qprs[0];
        }
      }
      // Save other qprs
      var otherQPRs = q.quest.qProductRxs ? q.quest.qProductRxs.filter((x) => !x.productRxGroupID) : [];

      // Setup main qpr
      qProductRx.fillSubscriptionFrequencyDays = selectedFrequency ? selectedFrequency.fillSubscriptionFrequencyDays : null;
      qProductRx.chargeSubscriptionFrequencyDays = selectedFrequency ? selectedFrequency.chargeSubscriptionFrequencyDays : null;
      qProductRx.numberOfSubscriptionChargesRequired = selectedFrequency ? selectedFrequency.numberOfSubscriptionChargesRequired : null;
      qProductRx.isSubscription = this.isSubscription;
      qProductRx.active = true;
      q.quest.qProductRxs = [qProductRx];

      // Add other qprs back with same subscription
      otherQPRs.forEach((x) => {
        x.fillSubscriptionFrequencyDays = selectedFrequency ? selectedFrequency.fillSubscriptionFrequencyDays : null;
        x.chargeSubscriptionFrequencyDays = selectedFrequency ? selectedFrequency.chargeSubscriptionFrequencyDays : null;
        x.numberOfSubscriptionChargesRequired = selectedFrequency ? selectedFrequency.numberOfSubscriptionChargesRequired : null;
        x.isSubscription = this.isSubscription;
        q.quest.qProductRxs.push(x);
      });
      this.setQuestionnaire({ q: q, t: this.QuestType });
    },
    switchToPayInFull() {
      var selectedFrequency = this.freqs.filter((x) => x.numberOfSubscriptionChargesRequired == 1)[0];
      this.changeFreq(selectedFrequency);
    },
    switchToInstallments() {
      var selectedFrequency = this.freqs.filter((x) => x.numberOfSubscriptionChargesRequired > 1)[0];
      this.changeFreq(selectedFrequency);
    },
    async getFreqsByProductID(id: number): Promise<ProductRxSubscriptionFrequencyViewModel[]> {
      var freqs = await store.dispatch("GetFreqsByProductRxID", id);
      if (freqs) {
        if (freqs.length > 0) {
          this.$emit("update:selectedProductSubscriptionAvailable", true);
        } else {
          this.$emit("update:selectedProductSubscriptionAvailable", false);
        }
      } else {
        this.$emit("update:selectedProductSubscriptionAvailable", false);
      }
      return freqs;
    },
    getProgramIncludes(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).ProgramIncludes;
      }
      return null;
    },
    toggleExpeditedShipping() {
      this.isExpeditedShipping = !this.isExpeditedShipping;
    },
    async importData(newVal: QuestionnaireViewModel) {
      if (!newVal) return;
      this.userAllowsChargeAndFillUponApproval = newVal.userAllowsChargeAndFillUponApproval;
      this.authToken = newVal.authToken;
      this.keepVaultID = newVal.keepVaultID;
      this.selectedProducts = [];
      this.selectedProductGroups = [];
      var qProductRx = newVal.qProductRxs && newVal.qProductRxs.length > 0 ? newVal.qProductRxs[0] : null;
      for (var i = 0; i < this.productGroups.length; i++) {
        for (var j = 0; j < newVal.qProductRxs.length; j++) {
          if (newVal.qProductRxs[j] && this.productGroups[i].id === newVal.qProductRxs[j].productRxGroupID) {
            this.selectedProductGroups.push(this.productGroups[i]);
          }
        }
      }
      for (i = 0; i < this.products.length; i++) {
        for (j = 0; j < newVal.qProductRxs.length; j++) {
          if (newVal.qProductRxs[j] && this.products[i].id === newVal.qProductRxs[j].productRxID) {
            this.selectedProducts.push(this.products[i]);
            //break;
          }
        }
      }
      if (this.selectedProducts == null) {
        this.selectedProducts = [] as AffiliateProductRx[];
      }
      //this.selectedProductTypeID = qProductRx && qProductRx.productTypeID !== null ? qProductRx.productTypeID : 1;
      this.isExpeditedShipping = newVal.isExpeditedShipping;
      if (this.isExpeditedShipping) {
        this.selectedShippingMethod = "overnight";
      } else {
        this.selectedShippingMethod = "standard";
      }
      if ((newVal.promoCode == null || newVal.promoCode.length == 0) && this.outsidePromo !== null) {
        newVal.promoCode = this.outsidePromo;
      }
      if ((newVal.promoCode == null || newVal.promoCode.length == 0) && this.PassedPromoCode !== null) {
        newVal.promoCode = this.PassedPromoCode;
      }
      if (this.promoCode != newVal.promoCode) {
        this.promoCode = newVal.promoCode;
        if (this.promoCode && this.promoCode.length > 0)
          this.GetPromoByCode(this.promoCode, null, () => {
            this.promoCode = null;
          });
      }
      this.isSubscription = qProductRx ? qProductRx.isSubscription : this.isSubscription;
      if (!this.isSubChargePayment && !this.q.chargeAllUpFront) this.toggleCartPopup();

      var freqs = await this.getFreqsByProductID(this.selectedProducts.filter((x) => x.productRxGroupID && x.productRxGroupID > 0)[0].id);
      freqs.forEach((x: ProductRxSubscriptionFrequencyViewModel) => {
        if (this.freqs.findIndex((y) => y.chargeSubscriptionFrequencyID == x.chargeSubscriptionFrequencyID && y.fillSubscriptionFrequencyID == x.fillSubscriptionFrequencyID) == -1) {
          this.freqs.push(x);
        }
      });
    },
    applyCouponBtn_click() {
      this.GetPromoByCode(this.promoCode, null, () => {
        swal("Oh no!", "Your coupon code wasn't found or may be expired. Please check that you spelled it correctly.", "warning");
      });
    },
    basicClick() {
      //document.getElementById("basicInfoSection").classList = "";
      document.getElementById("paymentSection").classList = "collapse";
      document.getElementById("shippingSection").classList = "collapse";
    },
    shippingClick() {
      document.getElementById("shippingSection").classList = "";
      document.getElementById("paymentSection").classList = "collapse";
      //document.getElementById("basicInfoSection").classList = "collapse";
    },
    paymentClick() {
      document.getElementById("paymentSection").classList = "";
      document.getElementById("shippingSection").classList = "collapse";
      //document.getElementById("basicInfoSection").classList = "collapse";
    },
    ccInfoInvalidChecker() {
      if (document.querySelectorAll(".CollectJSInlineIframe.CollectJSValid").length < 3) {
        this.ccInfoInvalid = true;
      } else {
        this.ccInfoInvalid = false;
      }
    },
    btnClearCreditCard_Click() {
      this.authToken = null;
      this.keepVaultID = false;
      this.setupCollect();
    },
    importPatient(newVal: PatientViewModel) {
      if (!newVal) return;
      this.address1 = newVal.streetAddress1;
      this.address2 = newVal.streetAddress2;
      this.city = newVal.city;
      this.state = newVal.state === null || newVal.state === "" ? newVal.homeState : newVal.state;
      this.zip = newVal.zipCode;
      this.homeAddress1 = newVal.homeStreetAddress1;
      this.homeAddress2 = newVal.homeStreetAddress2;
      this.homeCity = newVal.homeCity;
      this.homeState = newVal.homeState;
      this.homeZip = newVal.homeZipCode;
      this.shippingIsSame = newVal.shippingIsSame;
    },
    importQuest(newVal: QuestionnaireViewModel) {
      if (!newVal) return;
      this.userAllowsChargeAndFillUponApproval = newVal.userAllowsChargeAndFillUponApproval;
      this.authToken = newVal.authToken;
      this.keepVaultID = newVal.keepVaultID;
    },
    getPatientData() {
      var p = Object.assign({}, this.q.patient);
      p.streetAddress1 = this.address1;
      p.streetAddress2 = this.address2;
      p.city = this.city;
      p.state = this.state;
      p.zipCode = this.zip;
      p.homeStreetAddress1 = this.homeAddress1;
      p.homeStreetAddress2 = this.homeAddress2;
      p.homeCity = this.homeCity;
      p.homeState = this.homeState;
      p.homeZipCode = this.homeZip;
      p.shippingIsSame = this.shippingIsSame;
      return p;
    },
    getQuestData() {
      var q = Object.assign({}, this.q.quest);
      //console.log("getQuestData this.q");
      //console.log(this.q);
      q.userAllowsChargeAndFillUponApproval = this.userAllowsChargeAndFillUponApproval;
      q.authToken = this.authToken;
      q.keepVaultID = this.keepVaultID;
      q.isExpeditedShipping = this.isExpeditedShipping;
      q.promoCode = this.promoCode;
      return q;
    },
    next_click() {
      const CollectJS: ICollectJS = (window as any).CollectJS;
      this.errors = [];
      this.schema
        .validate(this, { abortEarly: false })
        .then(() => {
          this.errors = [];

          this.setLoading(true);

          if (this.q.noPaymentCollection == true) {
            // Does not need CC Information so no Validation is required
            this.$emit("saveandnavigate", "+", this.getPatientData(), this.getQuestData());
            return;
          }

          if (this.paymentInfoIsOptional || (this.authToken != null && this.authToken.length > 0) || this.keepVaultID) {
            // already have cc info
            this.$emit("saveandnavigate", "+", this.getPatientData(), this.getQuestData());
            return;
          }

          this.waitCCInfoValid(
            () => {
              // Success need to get cc info
              //console.log("Success need to get cc info");
              //console.log(event);
              CollectJS.startPaymentRequest(event);
            },
            () => {
              // Failure in cc info
              //console.log("Failure in cc info");
              this.setLoading(false);
              swal("", "Valid credit card information is required.", "warning");
            },
            6
          );
        })
        .catch((err: { inner: any[] }) => {
          err.inner.forEach((error: { path: string; message: string }): void => {
            this.errors.push({ key: error.path, value: error.message });
            //console.log({ key: error.path, value: error.message });
          });
        });
    },
    waitCCInfoValid(successCB: () => void, failureCB: () => void, retries: number) {
      window.setTimeout(() => {
        retries--;
        if (!this.ccInfoInvalid) {
          successCB();
        } else {
          if (retries > 0) {
            this.waitCCInfoValid(successCB, failureCB, retries);
          } else {
            failureCB();
          }
        }
      }, 500);
    },
    clearCouponBtn_click() {
      this.codePromo = null;
      this.promoCode = null;
    },
    prev_click() {
      this.$emit("saveandnavigate", "-", this.getPatientData(), this.getQuestData());
    },
    handleShippingIsSameClick(e: boolean) {
      this.validateShippingAddress(e);
    },
    async checkSupportedState(state: string | null, cb: { (supported: any): void; (supported: any): void; (arg0: any): void }) {
      //var quest = this.getQuestData();
      //console.log(`checkSupportedState q.questionnaireTypeID ${quest.questionnaireTypeID}`);
      //console.log(quest);
      //var stateSupported = await store.dispatch("CheckSupportedState", { state: state, p: this.getPatientData(), q: quest, t: quest.questionnaireTypeID, isRefill: this.isRefill, isPayment: this.isPayment });
      var p = this.getPatientData();
      console.log("p");
      console.log(p);
      var stateSupported = await store.dispatch("CheckSupportedState", { state: p.state, p: p, q: this.getQuestData(), t: this.q.quest.questionnaireTypeID, isPayment: this.isSubChargePayment });
      console.log("stateSupported");
      console.log(stateSupported);
      if (cb) cb(stateSupported);
    },
    validateShippingAddress(e: boolean) {
      if (e) {
        this.checkSupportedState(this.homeState, (supported) => {
          if (supported) {
            this.homeAddress1 = this.address1;
            this.homeAddress2 = this.address2;
            this.homeCity = this.city;
            this.homeState = this.state;
            this.homeZip = this.zip;
          } else {
            this.shippingIsSame = false;
            swal("", "Your Home State is not available for shipping.", "warning");
          }
        });
      } else {
        this.homeAddress1 = "";
        this.homeAddress2 = "";
        this.homeCity = "";
        this.homeState = this.patientStatesMustMatch ? this.state : "";
        this.homeZip = "";
      }
    },
    async setupCollect() {
      if ((this.authToken != null && this.authToken.length > 0) || this.keepVaultID) {
        this.setLoading(false);
        return false;
      }
      while (!this.IsCollectJSLoaded) {
        await this.sleep(100);
      }
      const CollectJS: ICollectJS = (window as any).CollectJS;
      CollectJS.configure({
        paymentSelector: "#customPayButton",
        styleSniffer: "true",
        customCss: {
          display: "block",
          width: "100%",
          height: "45px",
          padding: "0.375rem 0.75rem",
          "font-Size": "14px",
          "font-weight": "400",
          "line-height": "1.5",
          color: (this.Theme.Colors as { Key: string; Value: string }[]).find((x) => x.Key == "--m-text-color")?.Value,
          "background-color": (this.Theme.Colors as { Key: string; Value: string }[]).find((x) => x.Key == "--m-background")?.Value,
          "background-clip": "padding-box",
          border: "1px solid #ced4da",
          "border-radius": "0.25rem",
          transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
        },
        fields: {
          ccnumber: {
            selector: "#cardNum",
            title: "Card Number",
            placeholder: "Credit Card Number",
          },
          ccexp: {
            selector: "#expDate",
            title: "Card Expiration",
            placeholder: "Exp. Date (MM/YY)",
          },
          cvv: {
            display: "show",
            selector: "#secCode",
            title: "CVV Code",
            placeholder: "CVV",
          },
        },
        variant: "inline",
        callback: (response: { token: any }) => {
          this.authToken = response.token;
          //console.log(`this.authToken ${this.authToken}`);
          if (this.userAllowsChargeAndFillUponApproval) {
            this.keepVaultID = true;
          }
          this.setLoading(false);
          if (this.$gtm && this.$gtm.enabled() && this.Content.GTMConfig) {
            this.$gtm.trackEvent({
              event: "Pay and continue",
              category: "Program Checkout",
              action: "click",
              label: this.currentQTP.displayName,
              value: this.isSubChargePayment ? this.q.subChargeAmount : this.totalPriceComputed,
              noninteraction: false, // Optional
            });
          }
          this.$emit("saveandnavigate", "+", this.getPatientData(), this.getQuestData());
        },
        validationCallback: (field: string, status: string, message: string) => {
          if (status) {
            switch (field) {
              case "ccnumber":
                this.cardNumError = null;
                break;
              case "ccexp":
                this.expDateError = null;
                break;
              case "cvv":
                this.secCodeError = null;
                break;
              default:
            }
          } else {
            switch (field) {
              case "ccnumber":
                this.cardNumError = message;
                break;
              case "ccexp":
                this.expDateError = message;
                break;
              case "cvv":
                this.secCodeError = message;
                break;
              default:
            }
            this.setLoading(false);
          }
        },
        timeoutDuration: 10000,
        timeoutCallback: () => {
          this.setLoading(false);
          swal("", "There was an error processing your credit card. Please try again. If you continue to experience this issue, please contact us.", "warning");
        },
        fieldsAvailableCallback: () => {
          this.setLoading(false);
          if (!this.homeZip || this.homeZip?.length < 5) {
            this.shippingClick();
          }
        },
      });
      this.setLoading(false);
    },
    showUpsell() {
      this.showUpsellModal = true;
    },
    toggleCartPopup() {
      this.isCartPopupOpen = !this.isCartPopupOpen;
    },
    updateCheckColor() {
      if (this.address1 && this.city && this.state && this.zip?.length === 5) {
        this.checkColor = "#00A438";
      } else {
        this.checkColor = "rgba(128, 128, 128, 0.541)";
      }
    },
    getCartBottomText(contentJSON: string) {
      if (contentJSON) {
        return JSON.parse(contentJSON).CartBottomText;
      }
      return null;
    },
  },
  components: { QuestionInformationVueComponent, QuestionImageVueComponentVue, QuestionMultipleFreeTextVueComponentVue, QuestionSingleChoiceVueComponentVue, QuestionFreeTextVueComponentVue, QuestWrapperComponent, UpsellVueComponent, BModal },
});
