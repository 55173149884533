import { render, staticRenderFns } from "./PatientWidgetDataVueComponent.vue?vue&type=template&id=25d6fc0d&scoped=true&"
import script from "./PatientWidgetDataVueComponent.vue?vue&type=script&lang=ts&"
export * from "./PatientWidgetDataVueComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d6fc0d",
  null
  
)

export default component.exports