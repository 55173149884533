import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

import { AjaxReturnViewModel } from "@/interfaces/AjaxReturnViewModel";
import { CheckoutViewModel } from "@/interfaces/CheckoutViewModel";
import { CustomerDetailViewModel } from "@/interfaces/CustomerDetailViewModel";
import { OrderViewModel } from "@/interfaces/OrderViewModel";
import { OTCCartCheckoutViewModel } from "@/interfaces/OTCCartCheckoutViewModel";
import { OTCProductViewModel } from "@/interfaces/OTCProductViewModel";
import { TwoFactorTypeEnum } from "@/interfaces/TwoFactorTypeEnum";
import { Patient } from "@/interfaces/Patient";
import { PatientNote } from "@/interfaces/PatientNote";
import { PatientPortalViewModel } from "@/interfaces/PatientPortalViewModel";
import { PatientRegisterViewModel } from "@/interfaces/PatientRegisterViewModel";
import { PatientViewModel } from "@/interfaces/PatientViewModel";
import { PaymentViewModel } from "@/interfaces/PaymentViewModel";
import { PharmacySchedule } from "@/interfaces/PharmacySchedule";
import { PhotosUpdateViewModel } from "@/interfaces/PhotosUpdateViewModel";
import { PhotosViewModel } from "@/interfaces/PhotosViewModel";
import { PhotoVerifyViewModel } from "@/interfaces/PhotoVerifyViewModel";
import { PhysicianReviewDataViewModel } from "@/interfaces/PhysicianReviewViewModel";
import { QuestionnaireTypeProductViewModel } from "@/interfaces/QuestionnaireTypeProductViewModel";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import { Questions } from "@/interfaces/Questions";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { RefillViewModel } from "@/interfaces/RefillViewModel";
import { Rx } from "@/interfaces/Rx";
import { RxPaymentViewModel } from "@/interfaces/RxPaymentViewModel";
import { RxRefillViewModel } from "@/interfaces/RxRefillViewModel";
import { ShoppingCartItem } from "@/interfaces/ShoppingCartItem";
import { ShoppingCartViewModel } from "@/interfaces/ShoppingCartViewModel";
import { VisitScheduleViewModel } from "@/interfaces/VisitScheduleViewModel";
import router from "@/router";
import api from "@/webapi";
import { SubscriptionPlanViewModel } from "@/interfaces/SubscriptionPlanViewModel";
import { AccountSettingsViewModel } from "@/interfaces/AccountSettingsViewModel";
import { PharmacySpecialHours } from "@/interfaces/PharmacySpecialHours";
import { AdminUserViewModel } from "@/interfaces/AdminUserViewModel";
import { QuestionnaireProductRxsDetails } from "@/interfaces/QuestionnaireProductRxsDetails";
import { NewPromotionViewModel } from "@/interfaces/NewPromotionViewModel";
import { PromotionRx } from "@/interfaces/PromotionEditViewModel";
import { ReportDataManagementViewModel, ReportDataViewModel } from "@/interfaces/ReportDataViewModel";
import { AdminEmailUpdateViewModel } from "@/interfaces/AdminEmailUpdateViewModel";
import { LookupPatientAsPhysicianViewModel } from "@/interfaces/LookupPatientAsPhysicianViewModel";
import { PatientPortalRxContainer } from "@/interfaces/PatientPortalRxContainer";
import { LookupPatientAsSupportViewModel } from "@/interfaces/LookupPatientAsSupportViewModel";
import { OutgoingReport } from "@/interfaces/OutgoingReport";
import { UsersForAdminViewModel } from "@/interfaces/UsersForAdminViewModel";
import { ReportTableData } from "@/interfaces/ReportTableDataViewModel";
import { PatientVitalViewModel } from "@/interfaces/PatientVitalViewModel";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    MaintenanceDetails: {},
    CallToActionArray: [],
    SiteSetupTimeStamp: null as Date | null,
    Affiliate: {},
    Role: "",
    Token: "",
    RefreshToken: "",
    LastRefreshDate: new Date(),
    SignedIn: false,
    Content: {
      SiteTitle: "",
      HomeHeroText: "Create your own hero section here",
      HomeHeroSubText: "Everything is customizable from the image to the text and color. Use this section to show the benefits of your offering.",
      ShippingPaymentInfoTitle: "Where are we sending your medication?",
      ShippingPaymentInfoSubTitle: "All medicines are shipped in discreet packaging, keeping in mind your privacy at all times.",
      SiteHomepage: "",
      MaintenanceModeContent: "",
    },
    Theme: null as Record<string, unknown> | null,
    QuestionnaireTypeProducts: [] as QuestionnaireTypeProductViewModel[],
    OTCProducts: [] as OTCProductViewModel[],
    Cart: {},
    ShoppingCart: {} as ShoppingCartViewModel,
    ShowCart: false,
    Loading: false,
    Questionnaires: {} as Record<number, QuestViewModel>,
    Payments: {} as Record<number, PaymentViewModel>,
    Refills: {} as Record<number, RefillViewModel>,
    Photos: {} as Record<number, PhotosUpdateViewModel>,
    RxPayments: {} as Record<number, RxPaymentViewModel>,
    RxRefills: {} as Record<number, RxRefillViewModel>,
    SubscriptionPlans: {} as Record<number, SubscriptionPlanViewModel>,
    CurrentQuestType: null as number | null,
    CurrentRxID: null as number | null,
    CurrentSubscriptionProgramID: null as number | null,
    CurrentRefillNum: null as number | null,
    CurrentAddRxPatient: null as Patient | null,
    IsCollectJSLoaded: false as boolean,
    PhotoPath: null as string | null,
    FirstName: null as string | null,
    LastName: null as string | null,
    ShippingCarriers: [],
    UTM: null,
    PassedPromoCode: null,
    showCart: false as boolean,
    ShowSidebarAdmin: false as boolean,
    CustomerLookup: [] as LookupPatientAsSupportViewModel[],
    ShowMainMenu: false as boolean,
    ReportData: {} as ReportTableData,
    PreviousScreen: "",
    ScheduledMaintenance: "",
    ScheduledMaintenanceDuration: 0,
    ScheduledMaintenanceDateTime: "",
    PreviousMaintenanceResult: false as boolean,
    ExpectedMaintenanceStart: "",
    ExpectedMaintenanceBool: false,
    MaintenanceAffiliateArray: [],
    DefaultMaintenanceHTML: "",
    ReportUsersList: [],
    ReportManagementReportList: [],
    ReportManagementErrorList: [],
    ReportManagementDataTypes: [],
    ReportManagementAffiliateNames: [],
    ReportManagementSprocList: [],
    NotWithinMaintenanceWindow: 3600000,
    WithinMaintenanceWindow: 900000,
    FullPatientWidgetData: null,
  },
  getters: {
    getFullPatientWidgetData: (state) => state.FullPatientWidgetData,
    getNotWithinMaintenanceWindow: (state) => state.NotWithinMaintenanceWindow,
    getWithinMaintenanceWindow: (state) => state.WithinMaintenanceWindow,
    getReportManagementDataTypes: (state) => state.ReportManagementDataTypes,
    getReportManagementAffiliateNames: (state) => state.ReportManagementAffiliateNames,
    getReportManagementSprocList: (state) => state.ReportManagementSprocList,
    getScheduledMaintenanceDuration: (state) => state.ScheduledMaintenanceDuration,
    getScheduledMaintenanceDateTime: (state) => state.ScheduledMaintenanceDateTime,
    getReportManagementErrorList: (state) => state.ReportManagementErrorList,
    getReportManagementReportList: (state) => state.ReportManagementReportList,
    getReportUsersList: (state) => state.ReportUsersList,
    getDefaultMaintenanceHTML: (state) => state.DefaultMaintenanceHTML,
    getMaintenanceAffiliateArray: (state) => state.MaintenanceAffiliateArray,
    getExpectedMaintenanceStart: (state) => state.ExpectedMaintenanceStart,
    getExpectedMaintenanceBool: (state) => state.ExpectedMaintenanceBool,
    getPreviousScreen: (state) => state.PreviousScreen,
    getPreviousMaintenanceResult: (state) => state.PreviousMaintenanceResult,
    getMaintenanceDetails: (state) => state.MaintenanceDetails,
    getCallToActionArray: (state) => state.CallToActionArray,
    getScheduledMaintenance: (state) => state.ScheduledMaintenance,
    getAffiliate: (state) => state.Affiliate,
    getSignedIn: (state) => state.SignedIn,
    getTheme: (state) => state.Theme,
    getContent: (state) => state.Content,
    getLoading: (state) => state.Loading,
    getToken: (state) => state.Token,
    getRefreshToken: (state) => state.RefreshToken,
    getQuestionnaires: (state) => state.Questionnaires,
    getPayments: (state) => state.Payments,
    getRefills: (state) => state.Refills,
    getPhotos: (state) => state.Photos,
    getRxPayments: (state) => state.RxPayments,
    getRxRefills: (state) => state.RxRefills,
    getSubscriptionPlans: (state) => state.SubscriptionPlans,
    getShoppingCart: (state) => state.ShoppingCart,
    getCurrentQuestType: (state) => state.CurrentQuestType,
    getCurrentRefillNum: (state) => state.CurrentRefillNum,
    getIsCollectJSLoaded: (state) => state.IsCollectJSLoaded,
    getRole: (state) => state.Role,
    getPhotoPath: (state) => state.PhotoPath,
    getFirstName: (state) => state.FirstName,
    getLastName: (state) => state.LastName,
    getQuestionnaireTypeProducts: (state) => state.QuestionnaireTypeProducts,
    getOTCProducts: (state) => state.OTCProducts,
    getLastRefreshDate: (state) => state.LastRefreshDate,
    getCurrentAddRxPatient: (state) => state.CurrentAddRxPatient,
    getCurrentRxID: (state) => state.CurrentRxID,
    getCurrentSubscriptionProgramID: (state) => state.CurrentSubscriptionProgramID,
    getShippingCarriers: (state) => state.ShippingCarriers,
    getUTM: (state) => state.UTM,
    getShowCart: (state) => state.ShowCart,
    getShowSidebarAdmin: (state) => state.ShowSidebarAdmin,
    getSiteSetupTimeStamp: (state) => state.SiteSetupTimeStamp,
    getPassedPromoCode: (state) => state.PassedPromoCode,
    getCustomerLookup: (state) => state.CustomerLookup,
    getShowMainMenu: (state) => state.ShowMainMenu,
    getReportData: (state) => state.ReportData,
  },
  mutations: {
    initialiseStore(state) {
      // Check if the ID exists
      state.IsCollectJSLoaded = false;
      if (sessionStorage.getItem("s")) {
        const savedStore = sessionStorage.getItem("s");
        // Replace the state object with the stored item
        this.replaceState(Object.assign(state, JSON.parse(savedStore ? savedStore : "")));
        state.IsCollectJSLoaded = false;
      }
    },
    setFullPatientWidgetData(state, FullPatientWidgetData) {
      state.FullPatientWidgetData = FullPatientWidgetData;
    },
    setNotWithinMaintenanceWindow(state, NotWithinMaintenanceWindow) {
      state.NotWithinMaintenanceWindow = NotWithinMaintenanceWindow;
    },
    setWithinMaintenanceWindow(state, WithinMaintenanceWindow) {
      state.WithinMaintenanceWindow = WithinMaintenanceWindow;
    },
    setReportManagementSprocList(state, ReportManagementSprocList) {
      state.ReportManagementSprocList = ReportManagementSprocList;
    },
    setReportManagementAffiliateNames(state, ReportManagementAffiliateNames) {
      state.ReportManagementAffiliateNames = ReportManagementAffiliateNames;
    },
    setReportManagementDataTypes(state, ReportManagementDataTypes) {
      state.ReportManagementDataTypes = ReportManagementDataTypes;
    },
    setScheduledMaintenanceDuration(state, ScheduledMaintenanceDuration) {
      state.ScheduledMaintenanceDuration = ScheduledMaintenanceDuration;
    },
    setScheduledMaintenanceDateTime(state, ScheduledMaintenanceDateTime) {
      state.ScheduledMaintenanceDateTime = ScheduledMaintenanceDateTime;
    },
    setReportManagementErrorList(state, ReportManagementErrorList) {
      state.ReportManagementErrorList = ReportManagementErrorList;
    },
    setReportManagementReportList(state, ReportManagementReportList) {
      state.ReportManagementReportList = ReportManagementReportList;
    },
    setReportUsersList(state, ReportUsersList) {
      state.ReportUsersList = ReportUsersList;
    },
    setLocalMaintenanceModeContent(state, NewContent) {
      state.Content.MaintenanceModeContent = NewContent;
    },
    setDefaultMaintenanceHTML(state, DefaultMaintenanceHTML) {
      state.DefaultMaintenanceHTML = DefaultMaintenanceHTML;
    },
    setMaintenanceAffiliateArray(state, MaintenanceAffiliateArray) {
      state.MaintenanceAffiliateArray = MaintenanceAffiliateArray;
    },
    setExpectedMaintenanceBool(state, ExpectedMaintenanceBool) {
      state.ExpectedMaintenanceBool = ExpectedMaintenanceBool;
    },
    setExpectedMaintenanceStart(state, ExpectedMaintenanceStart) {
      state.ExpectedMaintenanceStart = ExpectedMaintenanceStart;
    },
    setScheduledMaintenance(state, ScheduledMaintenance) {
      state.ScheduledMaintenance = ScheduledMaintenance;
    },
    setPreviousScreen(state, PreviousScreen) {
      state.PreviousScreen = PreviousScreen;
    },
    setPreviousMaintenanceResult(state, PreviousMaintenanceResult) {
      state.PreviousMaintenanceResult = PreviousMaintenanceResult;
    },
    setMaintenanceDetails(state, MaintenanceDetails) {
      state.MaintenanceDetails = MaintenanceDetails;
    },
    setCallToActionArray(state, callToActionArray) {
      state.CallToActionArray = callToActionArray;
    },
    setAffiliate(state, Affiliate) {
      state.Affiliate = Affiliate;
    },
    setCurrentAddRxPatient(state, CurrentAddRxPatient) {
      state.CurrentAddRxPatient = CurrentAddRxPatient;
    },
    setContent(state, Content) {
      state.Content = Content;
    },
    setTheme(state, Theme) {
      state.Theme = Theme;
    },
    setQuestionnaireTypeProducts(state, QuestionnaireTypeProducts) {
      state.QuestionnaireTypeProducts = QuestionnaireTypeProducts;
    },
    setOTCProducts(state, OTCProducts) {
      state.OTCProducts = OTCProducts;
    },
    setLoading(state, Loading) {
      state.Loading = Loading;
    },
    setToken(state, Token) {
      state.Token = Token;
      api.defaults.headers.common["Authorization"] = `Bearer ${Token}`;
      if (Token && Token.length > 0) state.SignedIn = true;
      else state.SignedIn = false;
    },
    setRefreshToken(state, RefreshToken) {
      state.RefreshToken = RefreshToken;
      state.LastRefreshDate = new Date();
    },
    setQuestionnaire(state, payload: { q: QuestViewModel; t: number }) {
      const q = Object.assign({}, state.Questionnaires);
      q[payload.t] = payload.q;
      state.Questionnaires = q;
    },
    setQuestionnaires(state, questionnaires) {
      state.Questionnaires = questionnaires;
    },
    setPayment(state, payload: { p: PaymentViewModel; t: number }) {
      const p = Object.assign({}, state.Payments);
      p[payload.t] = payload.p;
      state.Payments = p;
    },
    setRxPayment(state, payload: { p: RxPaymentViewModel; rxID: number }) {
      const p = Object.assign({}, state.RxPayments);
      p[payload.rxID] = payload.p;
      state.RxPayments = p;
    },
    setRxRefill(state, payload: { r: RxRefillViewModel; rxID: number }) {
      const r = Object.assign({}, state.RxRefills);
      r[payload.rxID] = payload.r;
      state.RxRefills = r;
    },
    setSubscriptionPlan(state, payload: { s: SubscriptionPlanViewModel; subscriptionProgramID: number }) {
      const s = Object.assign({}, state.SubscriptionPlans);
      s[payload.subscriptionProgramID] = payload.s;
      state.SubscriptionPlans = s;
    },
    setPhotos(state, payload: { p: PhotosUpdateViewModel; t: number }) {
      const p = Object.assign({}, state.Photos);
      p[payload.t] = payload.p;
      state.Photos = p;
    },
    setPaymentQuest(state, payload: { q: QuestionnaireViewModel; t: number }) {
      const p = Object.assign({}, state.Payments);
      p[payload.t].quest = payload.q;
      state.Payments = p;
    },
    setPaymentPatient(state, payload: { p: PatientViewModel; q: QuestionnaireViewModel | null; t: number }) {
      const p = Object.assign({}, state.Payments);
      p[payload.t].patient = payload.p;
      p[payload.t].quest = payload.q ? payload.q : p[payload.t].quest;
      state.Payments = p;
    },
    setRefill(state, payload: { r: RefillViewModel; t: number }) {
      const r = Object.assign({}, state.Refills);
      r[payload.t] = payload.r;
      state.Refills = r;
    },
    setRefillQuest(state, payload: { q: QuestionnaireViewModel; t: number }) {
      const r = Object.assign({}, state.Refills);
      r[payload.t].quest = payload.q;
      state.Refills = r;
    },
    setShoppingCart(state, cart) {
      state.ShoppingCart = cart;
    },
    setCurrentQuestType(state, CurrentQuestType) {
      state.CurrentQuestType = CurrentQuestType;
    },
    setCurrentRefillNum(state, CurrentRefillNum) {
      state.CurrentRefillNum = CurrentRefillNum;
    },
    setIsCollectJSLoaded(state, IsCollectJSLoaded) {
      state.IsCollectJSLoaded = IsCollectJSLoaded;
    },
    setRole(state, Role) {
      state.Role = Role;
    },
    setPhotoPath(state, PhotoPath) {
      state.PhotoPath = PhotoPath;
    },
    setFirstName(state, FirstName) {
      state.FirstName = FirstName;
    },
    setLastName(state, LastName) {
      state.LastName = LastName;
    },
    setCurrentRxID(state, CurrentRxID) {
      state.CurrentRxID = CurrentRxID;
    },
    setCurrentSubscriptionProgramID(state, CurrentSubscriptionProgramID) {
      state.CurrentSubscriptionProgramID = CurrentSubscriptionProgramID;
    },
    setShippingCarriers(state, ShippingCarriers) {
      state.ShippingCarriers = ShippingCarriers;
    },
    setUTM(state, UTM) {
      state.UTM = UTM;
    },
    setShowCart(state, ShowCart) {
      state.ShowCart = ShowCart;
    },
    setSidebarAdmin(state, ShowSidebarAdmin) {
      state.ShowSidebarAdmin = ShowSidebarAdmin;
    },
    setSiteSetupTimeStamp(state, SiteSetupTimeStamp) {
      state.SiteSetupTimeStamp = SiteSetupTimeStamp;
    },
    setPassedPromoCode(state, PassedPromoCode) {
      state.PassedPromoCode = PassedPromoCode;
    },
    setCustomerLookup(state, CustomerLookup) {
      state.CustomerLookup = CustomerLookup;
    },
    setShowMainMenu(state, ShowMainMenu) {
      state.ShowMainMenu = ShowMainMenu;
    },
    setReportTableData(state, newReportTableData) {
      state.ReportData = {
        ...state.ReportData,
        reportTableData: newReportTableData,
      };
    },
    setFilteredData(state, newFilteredData) {
      state.ReportData = {
        ...state.ReportData,
        filteredData: newFilteredData,
      };
    },
    setActiveFilters(state, newActiveFilters) {
      state.ReportData = {
        ...state.ReportData,
        activeFilters: newActiveFilters,
      };
    },
    setCurrentPage(state, newCurrentPage) {
      state.ReportData = {
        ...state.ReportData,
        currentPage: newCurrentPage,
      };
    },
    setPageSize(state, newPageSize) {
      state.ReportData = {
        ...state.ReportData,
        pageSize: newPageSize,
      };
    },
  },
  actions: {
    async initialiseStore({ commit, getters }) {
      commit("initialiseStore");
      commit("setToken", getters.getToken);
      commit("setRefreshToken", getters.getRefreshToken);
      commit("setLoading", false);
    },
    async SavePhotos({ commit, state }, payload: { p: PhotosViewModel; t: number; photosOnly: boolean }) {
      try {
        let q = {} as QuestViewModel | PhotosUpdateViewModel;
        if (payload.photosOnly) q = Object.assign({}, state.Photos[payload.t]);
        else q = Object.assign({}, state.Questionnaires[payload.t]);
        const response = await api.post(`/api/Home/SavePhotos?t=${payload.t}`, payload.p);
        if (response && response.data && response.data.success) {
          q.patient = response.data.data;
          if (payload.photosOnly) commit("setPhotos", { q: q, t: payload.t });
          else commit("setQuestionnaire", { q: q, t: payload.t });
          commit("setPhotoPath", q.patient.photo2Path);
          return true;
        }
        q.errors = response.data ? response.data.errors : [];
        q.navButtonText = response.data ? response.data.navButtonText : "";
        q.navPageID = response.data ? response.data.navPageID : null;
        if (payload.photosOnly) commit("setPhotos", { q: q, t: payload.t });
        else commit("setQuestionnaire", { q: q, t: payload.t });
        return false;
      } catch (e) {
        let q = {} as QuestViewModel | PhotosUpdateViewModel;
        if (payload.photosOnly) q = Object.assign({}, state.Photos[payload.t]);
        else q = Object.assign({}, state.Questionnaires[payload.t]);
        q.errors = ["Something went wrong!"];
        q.navButtonText = "Click to retry.";
        q.navPageID = null;
        if (payload.photosOnly) commit("setPhotos", { q: q, t: payload.t });
        else commit("setQuestionnaire", { q: q, t: payload.t });
        return false;
      }
    },
    async SavePatient({ commit, state }, payload: { p: PatientViewModel; quest?: QuestionnaireViewModel; t: number; isPayment?: boolean }) {
      try {
        const q = payload.isPayment ? Object.assign({}, state.Payments[payload.t]) : Object.assign({}, state.Questionnaires[payload.t]);
        const response = await api.post("/api/Home/SavePatient", payload.p);
        console.log("SavePatient Resolved!");
        if (response && response.data && response.data.success) {
          q.quest = payload.quest ? payload.quest : q.quest;
          q.patient = response.data.data;
          if (payload.isPayment) commit("setPayment", { p: q, t: payload.t });
          else commit("setQuestionnaire", { q: q, t: payload.t });
          commit("setPhotoPath", q.patient.photo2Path);
          return true;
        }
        q.errors = response.data ? response.data.errors : [];
        q.navButtonText = response.data ? response.data.navButtonText : "";
        q.navPageID = response.data ? response.data.navPageID : null;
        if (payload.isPayment) commit("setPayment", { p: q, t: payload.t });
        else commit("setQuestionnaire", { q: q, t: payload.t });
        return false;
      } catch (e) {
        const q = payload.isPayment ? Object.assign({}, state.Payments[payload.t]) : Object.assign({}, state.Questionnaires[payload.t]);
        q.errors = ["Something went wrong!"];
        q.navButtonText = "Click to retry.";
        q.navPageID = null;
        if (payload.isPayment) commit("setPayment", { p: q, t: payload.t });
        else commit("setQuestionnaire", { q: q, t: payload.t });
        return false;
      }
    },
    async OTCCartCheckout({ commit, state }, p: OTCCartCheckoutViewModel): Promise<AjaxReturnViewModel<boolean> | null> {
      try {
        const resp = await api.post("/api/Home/OTCCartCheckout", p);
        if (resp) {
          return resp.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async SaveShoppingCartItem({ commit, state }, payload: [ShoppingCartItem]) {
      try {
        const resp = await api.post("/api/Home/SaveShoppingCartItem", payload);
        if (resp) {
          return resp.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async SaveSubscriptionPlan({ commit, state }, payload: { s: SubscriptionPlanViewModel; l?: boolean }) {
      try {
        let s = Object.assign({}, state.SubscriptionPlans[payload.s.subPlan.subscriptionProgramID]);
        s.questionsObj = payload.s.questionsObj;
        const response = await api.post(`/api/Home/SaveSubscriptionPlan?isLastQuestPage=${payload.l ? "true" : "false"}`, payload.s);
        if (response && response.data && response.data.success) {
          s = response.data.data;
          s.errors = response.data ? response.data.errors : [];
          s.navButtonText = response.data ? response.data.navButtonText : "";
          s.navPageID = response.data ? response.data.navPageID : null;
          commit("setSubscriptionPlan", { s: s, subscriptionProgramID: payload.s.subPlan.subscriptionProgramID });
          return true;
        }
        s.errors = response.data ? response.data.errors : [];
        s.navButtonText = response.data ? response.data.navButtonText : "";
        s.navPageID = response.data ? response.data.navPageID : null;
        commit("setSubscriptionPlan", { s: s, subscriptionProgramID: payload.s.subPlan.subscriptionProgramID });
        return false;
      } catch (e) {
        /*const q = Object.assign({}, state.Questionnaires[payload.t]);
        q.errors = ["Something went wrong!"];
        q.navButtonText = "Click to retry.";
        q.navPageID = null;
        commit("setQuestionnaire", { q: q, t: payload.t });
        return false;*/
      }
    },
    async SaveQuest({ commit, state }, payload: { q: QuestViewModel; t: number; l?: boolean }) {
      try {
        const q = Object.assign({}, state.Questionnaires[payload.t]);
        const response = await api.post(`/api/Home/SaveQuest?isLastQuestPage=${payload.l ? "true" : "false"}`, payload.q);
        if (response && response.data && response.data.success) {
          q.quest = response.data.data;
          q.errors = response.data ? response.data.errors : [];
          q.navButtonText = response.data ? response.data.navButtonText : "";
          q.navPageID = response.data ? response.data.navPageID : null;
          commit("setQuestionnaire", { q: q, t: payload.t });
          return true;
        }
        q.errors = response.data ? response.data.errors : [];
        q.navButtonText = response.data ? response.data.navButtonText : "";
        q.navPageID = response.data ? response.data.navPageID : null;
        commit("setQuestionnaire", { q: q, t: payload.t });
        return false;
      } catch (e) {
        const q = Object.assign({}, state.Questionnaires[payload.t]);
        q.errors = ["Something went wrong!"];
        q.navButtonText = "Click to retry.";
        q.navPageID = null;
        commit("setQuestionnaire", { q: q, t: payload.t });
        return false;
      }
    },
    async SaveSubChargePayment({ commit, state }, payload: { q: QuestionnaireViewModel; t: number; l?: boolean; a: number }) {
      try {
        const p = Object.assign({}, state.Payments[payload.t]);
        const response = await api.post(`/api/Home/SaveSubChargePayment?amt=${payload.a}`, payload.q);
        if (response && response.data && response.data.success) {
          p.quest = payload.q;
          p.errors = [];
          p.navButtonText = "";
          p.navPageID = null;
          commit("setPaymentQuest", { q: p.quest, t: payload.t });
          return true;
        }
        p.errors = response.data ? response.data.errors : [];
        p.navButtonText = response.data ? response.data.navButtonText : "";
        p.navPageID = response.data ? response.data.navPageID : null;
        commit("setPayment", { p: p, t: payload.t });
        return false;
      } catch (e) {
        const p = Object.assign({}, state.Payments[payload.t]);
        p.errors = ["Something went wrong!"];
        p.navButtonText = "Click to retry.";
        p.navPageID = null;
        commit("setPayment", { p: p, t: payload.t });
        return false;
      }
    },
    async SavePayment({ commit, state }, payload: { q: QuestionnaireViewModel; t: number; l?: boolean }) {
      try {
        const p = Object.assign({}, state.Payments[payload.t]);
        const response = await api.post(`/api/Home/SavePayment`, payload.q);
        if (response && response.data && response.data.success) {
          p.quest = payload.q;
          p.errors = [];
          p.navButtonText = "";
          p.navPageID = null;
          commit("setPaymentQuest", { q: p.quest, t: payload.t });
          return true;
        }
        p.errors = response.data ? response.data.errors : [];
        p.navButtonText = response.data ? response.data.navButtonText : "";
        p.navPageID = response.data ? response.data.navPageID : null;
        commit("setPayment", { p: p, t: payload.t });
        return false;
      } catch (e) {
        const p = Object.assign({}, state.Payments[payload.t]);
        p.errors = ["Something went wrong!"];
        p.navButtonText = "Click to retry.";
        p.navPageID = null;
        commit("setPayment", { p: p, t: payload.t });
        return false;
      }
    },
    async SaveRxPayment({ commit, state }, payload: { r: Rx }) {
      try {
        const p = Object.assign({}, state.RxPayments[payload.r.id]);
        const response = await api.post(`/api/Home/SaveRxPayment`, payload.r);
        if (response && response.data && response.data.success) {
          p.rx = payload.r;
          p.errors = [];
          p.navButtonText = "";
          p.navPageID = null;
          commit("setRxPayment", { p: p, rxID: payload.r.id });
          return true;
        }
        p.errors = response.data ? response.data.errors : [];
        p.navButtonText = response.data ? response.data.navButtonText : "";
        p.navPageID = response.data ? response.data.navPageID : null;
        commit("setRxPayment", { p: p, rxID: payload.r.id });
        return false;
      } catch (e) {
        const p = Object.assign({}, state.RxPayments[payload.r.id]);
        p.errors = ["Something went wrong!"];
        p.navButtonText = "Click to retry.";
        p.navPageID = null;
        commit("setRxPayment", { p: p, rxID: payload.r.id });
        return false;
      }
    },
    async SaveRxRefill({ commit, state }, payload: { r: Rx }) {
      try {
        const p = Object.assign({}, state.RxRefills[payload.r.id]);
        const response = await api.post(`/api/Home/SaveRxPayment`, payload.r);
        if (response && response.data && response.data.success) {
          p.rx = payload.r;
          p.errors = [];
          p.navButtonText = "";
          p.navPageID = null;
          commit("setRxRefill", { r: p, rxID: payload.r.id });
          return true;
        }
        p.errors = response.data ? response.data.errors : [];
        p.navButtonText = response.data ? response.data.navButtonText : "";
        p.navPageID = response.data ? response.data.navPageID : null;
        commit("setRxRefill", { r: p, rxID: payload.r.id });
        return false;
      } catch (e) {
        const p = Object.assign({}, state.RxRefills[payload.r.id]);
        p.errors = ["Something went wrong!"];
        p.navButtonText = "Click to retry.";
        p.navPageID = null;
        commit("setRxRefill", { r: p, rxID: payload.r.id });
        return false;
      }
    },
    async SaveModify({ commit, state }, payload: { rVM: RefillViewModel; num: number }) {
      try {
        const q = Object.assign({}, state.Refills[payload.rVM.quest.questionnaireTypeID]);
        const response = await api.post(`/api/Home/SaveModify?num=${payload.num}`, { quest: payload.rVM.quest, refillQuestionsObj: payload.rVM.refillQuestionsObj });
        if (response && response.data && response.data.success) {
          q.quest = response.data.data;
          commit("setRefill", { r: q, t: payload.rVM.quest.questionnaireTypeID });
          return true;
        }
        q.errors = response.data ? response.data.errors : [];
        q.navButtonText = response.data ? response.data.navButtonText : "";
        q.navPageID = response.data ? response.data.navPageID : null;
        commit("setRefill", { r: q, t: payload.rVM.quest.questionnaireTypeID });
        return false;
      } catch (e) {
        const q = Object.assign({}, state.Refills[payload.rVM.quest.questionnaireTypeID]);
        q.errors = ["Something went wrong!"];
        q.navButtonText = "Click to retry.";
        q.navPageID = null;
        commit("setRefill", { r: q, t: payload.rVM.quest.questionnaireTypeID });
        return false;
      }
    },
    async SaveRefill({ commit, state }, payload: { rVM: RefillViewModel; num: number }) {
      try {
        const q = Object.assign({}, state.Refills[payload.rVM.quest.questionnaireTypeID]);
        const response = await api.post(`/api/Home/SaveRefill?num=${payload.num}`, { quest: payload.rVM.quest, refillQuestionsObj: payload.rVM.refillQuestionsObj });
        if (response && response.data && response.data.success) {
          q.quest = response.data.data;
          commit("setRefill", { r: q, t: payload.rVM.quest.questionnaireTypeID });
          return true;
        }
        q.errors = response.data ? response.data.errors : [];
        q.navButtonText = response.data ? response.data.navButtonText : "";
        q.navPageID = response.data ? response.data.navPageID : null;
        commit("setRefill", { r: q, t: payload.rVM.quest.questionnaireTypeID });
        return false;
      } catch (e) {
        const q = Object.assign({}, state.Refills[payload.rVM.quest.questionnaireTypeID]);
        q.errors = ["Something went wrong!"];
        q.navButtonText = "Click to retry.";
        q.navPageID = null;
        commit("setRefill", { r: q, t: payload.rVM.quest.questionnaireTypeID });
        return false;
      }
    },
    async SaveRefillQuestions({ commit, state }, payload: { q: Questions; t: number; l?: boolean; rxID?: number }) {
      try {
        const q: RefillViewModel = Object.assign({}, state.Refills[payload.t]);
        const response = await api.post(`/api/Home/SaveRefillQuestions?t=${payload.t}&isLastQuestPage=${payload.l ? "true" : "false"}${payload.rxID ? "&rxID=" + payload.rxID : ""}`, payload.q);
        if (response && response.data && response.data.success) {
          q.refillQuestionsObj = payload.q;
          q.errors = response.data ? response.data.errors : [];
          q.navButtonText = response.data ? response.data.navButtonText : "";
          q.navPageID = response.data ? response.data.navPageID : null;
          if (response.data.data && (response.data.data.isHardStop || response.data.data.hardStop)) {
            q.quest.hardStop = true;
            q.hardStop = true;
            commit("setRefill", { r: q, t: payload.t });
            return false;
          }
          commit("setRefill", { r: q, t: payload.t });
          return response.data.data;
        }
        if (response && response.data && (response.data.data.refillHeld || response.data.data.hardStop)) {
          q.errors = response.data ? response.data.errors : [];
          q.navButtonText = response.data ? response.data.navButtonText : "";
          q.navPageID = response.data ? response.data.navPageID : null;
          q.refillHeld = response.data.data.refillHeld ? true : false;
          q.hardStop = response.data.data.hardStop ? true : false;
        } else {
          q.errors = ["Something went wrong!"];
          q.navButtonText = "Click to retry.";
          q.navPageID = null;
        }
        commit("setRefill", { r: q, t: payload.t });
        return false;
      } catch (e) {
        const q = Object.assign({}, state.Refills[payload.t]);
        q.errors = ["Something went wrong!"];
        q.navButtonText = "Click to retry.";
        q.navPageID = null;
        commit("setRefill", { r: q, t: payload.t });
        return false;
      }
    },
    async Refresh({ commit, state, dispatch }) {
      try {
        if (!state.RefreshToken) return true; //skipping refresh one is already in progress
        const postData = { token: state.Token, refreshToken: state.RefreshToken };
        commit("setRefreshToken", null);
        const response = await axios.post("/api/members/refresh", postData);
        if (response && response.data) {
          commit("setToken", response.data.token);
          commit("setRefreshToken", response.data.refreshToken);
          commit("setRole", response.data.role);
          commit("setPhotoPath", response.data.photoPath);
          commit("setFirstName", response.data.firstName);
          commit("setLastName", response.data.lastName);
          return true;
        }
        dispatch("SignOut");
        if (router.currentRoute.name != "signin") router.push({ name: "signin", query: { r: router.currentRoute.fullPath } });
        return false;
      } catch (e) {
        dispatch("SignOut");
        if (router.currentRoute.name != "signin") router.push({ name: "signin", query: { r: router.currentRoute.fullPath } });
        return false;
      }
    },
    async SignIn({ commit }, { u, p }) {
      try {
        const response = await axios.post("/api/members/authentication", { u, p });
        if (response && response.data) {
          if (!response.data.twoFactorEnabled) {
            commit("setToken", response.data.token);
            commit("setRefreshToken", response.data.refreshToken);
            commit("setRole", response.data.role);
            commit("setPhotoPath", response.data.photoPath);
            commit("setFirstName", response.data.firstName);
            commit("setLastName", response.data.lastName);
            return { status: true };
          }
          return { status: true, token: response.data.token, twoFactorEnabled: response.data.twoFactorEnabled, authMethods: response.data.authMethods };
        }
        return { status: false };
      } catch (e) {
        return { status: false };
      }
    },
    async SignInMFA({ commit }, { c, method, token }) {
      let err = false;
      const response = await axios.post("/api/members/twofactor", { c, method }, { headers: { Authorization: `Bearer ${token}` } }).catch(function (e) {
        if (e.response && e.response.status == 401) {
          err = true;
        }
      });
      if (err) {
        return null;
      }
      try {
        if (response && response.data?.token) {
          commit("setToken", response.data.token);
          commit("setRefreshToken", response.data.refreshToken);
          commit("setRole", response.data.role);
          commit("setPhotoPath", response.data.photoPath);
          commit("setFirstName", response.data.firstName);
          commit("setLastName", response.data.lastName);
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    async TwoFactorToken({ state, commit }, { method, token }) {
      try {
        const response = await axios.post("/api/members/twofactortoken", { method }, { headers: { Authorization: `Bearer ${token ? token : state.Token}` } });
        if (response && response.data) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    async SignInOpenID({ commit }, vm) {
      try {
        commit("setToken", vm.Token);
        commit("setRefreshToken", vm.RefreshToken);
        commit("setRole", vm.Role);
        commit("setPhotoPath", vm.PhotoPath);
        commit("setFirstName", vm.FirstName);
        commit("setLastName", vm.LastName);
      } catch (e) {
        console.log(e);
      }
    },
    clearState({ commit }) {
      commit("setShowMainMenu", false);
      commit("setCustomerLookup", []);
      commit("setSidebarAdmin", false);
      commit("setShowCart", false);
      commit("setPassedPromoCode", null);
      commit("setUTM", null);
      commit("setShippingCarriers", []);
      commit("setLastName", null);
      commit("setFirstName", null);
      commit("setPhotoPath", null);
      commit("setIsCollectJSLoaded", null);
      commit("setCurrentAddRxPatient", null);
      commit("setSiteSetupTimeStamp", null);
      commit("setAffiliate", {});
      commit("setRole", "");
      commit("setToken", "");
      commit("setRefreshToken", "");
      commit("setQuestionnaireTypeProducts", []);
      commit("setOTCProducts", []);
      commit("setShoppingCart", {} as ShoppingCartViewModel);
      commit("setShowCart", false);
      commit("setLoading", false);
      commit("setQuestionnaires", []);
      commit("setPayment", {});
      commit("setRefill", {});
      commit("setPhotos", {});
      commit("setRxPayment", {});
      commit("setRxRefill", {});
      commit("setSubscriptionPlan", {});
      commit("setCurrentQuestType", 0);
      commit("setCurrentRxID", null);
      commit("setCurrentSubscriptionProgramID", null);
      commit("setCurrentRefillNum", null);
      commit("setFullPatientWidgetData", null);
      sessionStorage.clear();
    },
    async SignOut({ commit, dispatch }) {
      const holder = sessionStorage.getItem("s");
      const objHolder = JSON.parse(holder!);
      dispatch("clearState");
      commit("setTheme", objHolder.Theme);
      dispatch("getSiteSetup");
    },
    async GetAccountSettings({ commit }): Promise<AjaxReturnViewModel<AccountSettingsViewModel> | null> {
      try {
        const settings = await api.get("/api/members/accountsettings");
        if (settings?.data) {
          return settings.data.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async UpdateAccountSettings({ commit }, newSettings: AccountSettingsViewModel): Promise<AjaxReturnViewModel<AccountSettingsViewModel> | null> {
      try {
        const settings = await api.post("/api/members/accountsettings", newSettings);
        if (settings?.data) {
          return settings.data.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async setupTwoFactor({ commit }, payload) {
      try {
        const settings = await api.post("/api/members/setupTwoFactor", payload);
        if (settings?.data) {
          return settings.data;
        }
        return null;
      } catch (e) {
        console.log(e);
      }
    },
    async disableTwoFactor({ commit }) {
      try {
        const settings = await api.get("/api/members/disableTwoFactor");
        if (settings?.data) {
          return settings.data.data;
        }
        return null;
      } catch (e) {
        console.log(e);
      }
    },
    async GetFreqsByProductRxID({ commit }, productRxID: number) {
      try {
        const result = await api.get(`/api/Home/GetFreqsByProductRxID?productRxID=${productRxID}`);
        return result.data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async getSiteSetup({ commit, state }) {
      if (state.SiteSetupTimeStamp && new Date(new Date(state.SiteSetupTimeStamp).getTime() + 30 * 60000) > new Date()) return;
      try {
        const siteSetup = await api.get("/api/Home/GetSiteSetup");
        if (!siteSetup || !siteSetup.data) return;
        if (siteSetup.data.affiliate.themeJSON) commit("setTheme", JSON.parse(siteSetup.data.affiliate.themeJSON));
        if (siteSetup.data.affiliate.contentJSON) commit("setContent", JSON.parse(siteSetup.data.affiliate.contentJSON));
        if (siteSetup.data.questionnaireTypeProducts) commit("setQuestionnaireTypeProducts", siteSetup.data.questionnaireTypeProducts);
        if (siteSetup.data.otcProducts) commit("setOTCProducts", siteSetup.data.otcProducts);
        if (siteSetup.data.affiliate) commit("setAffiliate", siteSetup.data.affiliate);
        commit("setSiteSetupTimeStamp", new Date());
      } catch (e) {
        console.log(e);
      }
    },
    async FWDMSGToSupport({ commit, state }, payload: { patientMessageID: number; patientAffiliateID: number }) {
      try {
        const result = await api.post(`/api/PatientMessages/FWDMSGToSupport?patientMessageID=${payload.patientMessageID}&patientAffiliateID=${payload.patientAffiliateID}`);
        if (!result || !result.data || !result.data.success) return false;
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async FWDMSGToPhysician({ commit, state }, payload: { patientMessageID: number; patientAffiliateID: number; questionnaireTypeID: number; patientUserID: number }) {
      try {
        const result = await api.post(`/api/PatientMessages/FWDMSGToPhysician?patientMessageID=${payload.patientMessageID}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireTypeID=${payload.questionnaireTypeID}&patientUserID=${payload.patientUserID}`);
        if (!result || !result.data || !result.data.success) return false;
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async GetCannedMessages({ commit, state }) {
      try {
        const result = await api.get(`/api/PatientMessages/GetCannedMessages`);
        if (!result || !result.data || !result.data.success) return null;
        return result.data.data;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetPatientMessages({ commit, state }, payload: { patientId: number; patientAffiliateID: number; questionnaireTypeID: number }) {
      try {
        const result = await api.post(`/api/PatientMessages/GetPatientMessages?patientId=${payload.patientId}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireTypeID=${payload.questionnaireTypeID}`);
        if (!result || !result.data || !result.data.success) return null;
        return result.data.data;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async MarkPatientMessageAsRead({ commit, state }, payload: { messageId: number; patientAffiliateID: number }) {
      try {
        const result = await api.post(`/api/PatientMessages/MarkPatientMessageAsRead?messageId=${payload.messageId}&patientAffiliateID=${payload.patientAffiliateID}`);
        if (!result || !result.data || !result.data.success) return false;
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async SendPatientMessage({ commit, state }, payload: { patientId: number; patientAffiliateID: number; questionnaireTypeID: number; message: string; folderType: number; rxID?: number | null; attachment?: string | null; attachmentType?: number | null }) {
      try {
        const bodyPayload = {
          patientId: payload.patientId,
          patientAffiliateID: payload.patientAffiliateID,
          message: payload.message,
          folderType: payload.folderType,
          rxID: payload.rxID || 0, // Provide a default value of 0 if rxID is not provided or is null
          attachment: payload.attachment || "", // Provide a default empty string if attachment is not provided or is null
          attachmentType: payload.attachmentType || null, // Provide null if attachmentType is not provided or is null
        };

        const result = await api.post(`/api/PatientMessages/SendPatientMessage`, bodyPayload);
        if (!result || !result.data || !result.data.success) return null;
        return result.data.data;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetPromoByCode({ commit }, payload: { t: number | number; code: string; notInitialQuest?: null | boolean }) {
      try {
        const result = await api.get(`/api/Home/GetPromoByCode?t=${payload.t}&code=${payload.code}&notInitialQuest=${payload.notInitialQuest ? payload.notInitialQuest : false}`);
        console.log("GetPromoByCode");
        console.log(result.data);
        return result.data;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async CheckSupportedState({ commit, state }, payload: { state: string; p: PatientViewModel; q: QuestionnaireViewModel | null; t: number; isRefill?: boolean; isPayment?: boolean; isPatientProfile?: boolean }) {
      try {
        let url = `/api/Home/CheckSupportedState?state=${payload.state}&t=${payload.t}`;
        if (payload.isPatientProfile) url = `/api/Home/CheckSupportedStateNoT?state=${payload.state}`;
        if (payload.isPatientProfile && state.Role == "Support") url = `/api/Support/CheckSupportedStateNoT?state=${payload.state}&patientUserID=${payload.p.userID}&patientAffiliateID=${payload.p.affiliateID}`;
        if (payload.isPatientProfile && state.Role == "Pharmacy") url = `/api/Pharmacy/CheckSupportedStateNoT?state=${payload.state}&patientUserID=${payload.p.userID}&patientAffiliateID=${payload.p.affiliateID}`;
        if (payload.isPatientProfile && state.Role == "AffiliateAdmin") url = `/api/affiliateadmin/CheckSupportedStateNoT?state=${payload.state}&patientUserID=${payload.p.userID}&patientAffiliateID=${payload.p.affiliateID}`;

        const result = await api.post(url);
        let q: RefillViewModel | QuestViewModel | PaymentViewModel | any = {};
        if (payload.isPayment) q = Object.assign({}, state.Payments[payload.t]);
        else if (payload.isRefill) q = Object.assign({}, state.Refills[payload.t]);
        else if (payload.isPatientProfile) q = null;
        else q = Object.assign({}, state.Questionnaires[payload.t]);

        payload.p.homeState = result.data && result.data.active ? result.data.stateName : !payload.isPatientProfile ? q.patient.homeState : payload.p.homeState;
        payload.p.state = result.data && result.data.active && ((payload.isPatientProfile && payload.p.shippingIsSame) || (q && q.patient.shippingIsSame)) ? result.data.stateName : !payload.isPatientProfile ? q.patient.state : payload.p.state;

        if (!payload.isPatientProfile) {
          q.patient = payload.p;
          q.visitRequired = result.data && result.data.visitRequired ? true : false;
          q.quest = payload.q ? payload.q : q.quest;
        }

        if (payload.isPayment) commit("setPayment", { p: q, t: payload.t });
        else if (payload.isRefill) commit("setRefill", { r: q, t: payload.t });
        else if (payload.isPatientProfile) q = null;
        else commit("setQuestionnaire", { q: q, t: payload.t });

        return result.data && result.data.active ? true : false;
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    async GetCheckout({ commit }): Promise<AjaxReturnViewModel<CheckoutViewModel> | null> {
      try {
        const vm = await api.get("/api/Home/GetCheckout");
        return vm.data;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    getStoredShoppingCart({ commit, state }) {
      try {
        const storedCart = localStorage.getItem("ShoppingCart");
        if (storedCart) {
          commit("setShoppingCart", JSON.parse(storedCart));
          return;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async GetShoppingCart({ commit }) {
      try {
        const cart = await api.get("/api/Home/GetShoppingCart");
        if (cart?.data?.cart) {
          //commit("setShoppingCart", cart.data.cart);
          return cart.data.cart;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async GetStarted({ commit, state }, payload: { t: number }) {
      const eq = Object.assign({}, state.Questionnaires[payload.t]);
      const q: QuestViewModel | any = eq ? eq : {};
      try {
        const response = await api.get(`/api/Home/GetStarted?t=${payload.t}`);
        if (response && response.data && response.data.success) {
          q.affiliateID = response.data.data.affiliateID;
          q.products = response.data.data.products;
          q.productGroups = response.data.data.productGroups;
          q.patientStates = response.data.data.patientStates;
          q.shippableStates = response.data.data.shippableStates;
          q.onlineDoctorConsultFee = response.data.data.onlineDoctorConsultFee;
          q.oldOnlineDoctorConsultFee = response.data.data.oldOnlineDoctorConsultFee;
          q.expeditedShippingFee = response.data.data.expeditedShippingFee;
          q.standardShippingFee = response.data.data.standardShippingFee;
          q.quest = q.quest && q.quest.qProductRxs && q.quest.qProductRxs.length > 0 ? q.quest : response.data.data.quest;
          q.chooseProductPriorToAuth = response.data.data.chooseProductPriorToAuth;
          q.confirmTerms = response.data.data.confirmTerms;
          q.getStartedProductsAfterQuestionnaire = response.data.data.getStartedProductsAfterQuestionnaire;
          commit("setQuestionnaire", { q: q, t: payload.t });
          return response.data;
        }
        q.errors = response.data ? response.data.errors : [];
        q.navButtonText = response.data ? response.data.navButtonText : "";
        q.navPageID = response.data ? response.data.navPageID : null;
        commit("setQuestionnaire", { q: q, t: payload.t });
        return { data: q };
      } catch (e) {
        q.errors = ["Something went wrong!"];
        q.navButtonText = "Click to retry.";
        q.navPageID = null;
        commit("setQuestionnaire", { q: q, t: payload.t });
        return { data: q };
      }
    },
    async GetQuestionnaire({ commit }, payload: { t: number }) {
      let q: QuestViewModel | any = {};
      try {
        const response = await api.get(`/api/Home/GetQuestionnaire?t=${payload.t}`);
        if (response && response.data && response.data.success) {
          q = response.data.data;
          commit("setQuestionnaire", { q: q, t: payload.t });
          return response.data;
        }
        q.errors = response.data ? response.data.errors : [];
        q.navButtonText = response.data ? response.data.navButtonText : "";
        q.navPageID = response.data ? response.data.navPageID : null;
        commit("setQuestionnaire", { q: q, t: payload.t });
        return { data: q };
      } catch (e) {
        q.errors = ["Something went wrong!"];
        q.navButtonText = "Click to retry.";
        q.navPageID = null;
        commit("setQuestionnaire", { q: q, t: payload.t });
        return { data: q };
      }
    },
    async GetSubscriptionPlan({ commit }, payload: { subscriptionProgramID: number }) {
      let q: SubscriptionPlanViewModel | any = {};
      try {
        const response = await api.get(`/api/Home/GetSubscriptionPlan?subscriptionProgramID=${payload.subscriptionProgramID}`);
        if (response && response.data && response.data.success) {
          q = response.data.data;
          commit("setSubscriptionPlan", { s: q, subscriptionProgramID: payload.subscriptionProgramID });
          return response.data;
        }
        q.errors = response.data ? response.data.errors : [];
        q.navButtonText = response.data ? response.data.navButtonText : "";
        q.navPageID = response.data ? response.data.navPageID : null;
        commit("setSubscriptionPlan", { s: q, subscriptionProgramID: payload.subscriptionProgramID });
        return { data: q };
      } catch (e) {
        q.errors = ["Something went wrong!"];
        q.navButtonText = "Click to retry.";
        q.navPageID = null;
        commit("setSubscriptionPlan", { s: q, subscriptionProgramID: payload.subscriptionProgramID });
        return { data: q };
      }
    },
    async GetSubChargePayment({ commit }, payload: { t: number }) {
      let p: PaymentViewModel | any = {};
      try {
        const response = await api.get(`/api/Home/GetSubChargePayment?t=${payload.t}`);
        if (response && response.data && response.data.success) {
          p = response.data.data;
          commit("setPayment", { p: p, t: payload.t });
          return response.data;
        }
        p.errors = response.data ? response.data.errors : [];
        p.navButtonText = response.data ? response.data.navButtonText : "";
        p.navPageID = response.data ? response.data.navPageID : null;
        commit("setPayment", { p: p, t: payload.t });
        return { data: p };
      } catch (e) {
        p.errors = ["Something went wrong!"];
        p.navButtonText = "Click to retry.";
        p.navPageID = null;
        commit("setPayment", { p: p, t: payload.t });
        return { data: p };
      }
    },
    async GetPayment({ commit }, payload: { t: number }) {
      let p: PaymentViewModel | any = {};
      try {
        const response = await api.get(`/api/Home/GetPayment?t=${payload.t}`);
        if (response && response.data && response.data.success) {
          p = response.data.data;
          commit("setPayment", { p: p, t: payload.t });
          return response.data;
        }
        p.errors = response.data ? response.data.errors : [];
        p.navButtonText = response.data ? response.data.navButtonText : "";
        p.navPageID = response.data ? response.data.navPageID : null;
        commit("setPayment", { p: p, t: payload.t });
        return { data: p };
      } catch (e) {
        p.errors = ["Something went wrong!"];
        p.navButtonText = "Click to retry.";
        p.navPageID = null;
        commit("setPayment", { p: p, t: payload.t });
        return { data: p };
      }
    },
    async GetPhotos({ commit }, payload: { t: number }) {
      let p: PhotosUpdateViewModel | any = {};
      try {
        const response = await api.get(`/api/Home/GetPhotos?t=${payload.t}`);
        if (response && response.data && response.data.success) {
          p = response.data.data;
          commit("setPhotos", { p: p, t: payload.t });
          return response.data;
        }
        p.errors = response.data ? response.data.errors : [];
        p.navButtonText = response.data ? response.data.navButtonText : "";
        p.navPageID = response.data ? response.data.navPageID : null;
        commit("setPhotos", { p: p, t: payload.t });
        return { data: p };
      } catch (e) {
        p.errors = ["Something went wrong!"];
        p.navButtonText = "Click to retry.";
        p.navPageID = null;
        commit("setPhotos", { p: p, t: payload.t });
        return { data: p };
      }
    },
    async GetRxRefill({ commit }, payload: { rxID: number; refillNum: number }) {
      let p: RxRefillViewModel | any = {};
      try {
        const response = await api.get(`/api/Home/GetRxRefill?rxID=${payload.rxID}&refillNum=${payload.refillNum}`);
        if (response && response.data && response.data.success) {
          p = response.data.data;
          commit("setRxRefill", { r: p, rxID: payload.rxID });
          return response.data;
        }
        p.errors = response.data ? response.data.errors : [];
        p.navButtonText = response.data ? response.data.navButtonText : "";
        p.navPageID = response.data ? response.data.navPageID : null;
        commit("setRxRefill", { r: p, rxID: payload.rxID });
        return { data: p };
      } catch (e) {
        p.errors = ["Something went wrong!"];
        p.navButtonText = "Click to retry.";
        p.navPageID = null;
        commit("setRxRefill", { r: p, rxID: payload.rxID });
        return { data: p };
      }
    },
    async GetRxPayment({ commit }, payload: { rxID: number }) {
      let p: RxPaymentViewModel | any = {};
      try {
        const response = await api.get(`/api/Home/GetRxPayment?rxID=${payload.rxID}`);
        if (response && response.data && response.data.success) {
          p = response.data.data;
          commit("setRxPayment", { p: p, rxID: payload.rxID });
          return response.data;
        }
        p.errors = response.data ? response.data.errors : [];
        p.navButtonText = response.data ? response.data.navButtonText : "";
        p.navPageID = response.data ? response.data.navPageID : null;
        commit("setRxPayment", { p: p, rxID: payload.rxID });
        return { data: p };
      } catch (e) {
        p.errors = ["Something went wrong!"];
        p.navButtonText = "Click to retry.";
        p.navPageID = null;
        commit("setRxPayment", { p: p, rxID: payload.rxID });
        return { data: p };
      }
    },
    async GetModify({ commit }, payload: { t: number; num: number }) {
      let r: RefillViewModel | any = {};
      try {
        const response = await api.get(`/api/Home/GetModify?t=${payload.t}&num=${payload.num}`);
        if (response && response.data && response.data.success) {
          r = response.data.data;
          r.errors = response.data ? response.data.errors : [];
          r.navButtonText = response.data ? response.data.navButtonText : "";
          r.navPageID = response.data ? response.data.navPageID : null;
          commit("setRefill", { r: r, t: payload.t });
          return response.data;
        }
        r.errors = response.data ? response.data.errors : [];
        r.navButtonText = response.data ? response.data.navButtonText : "";
        r.navPageID = response.data ? response.data.navPageID : null;
        commit("setRefill", { r: r, t: payload.t });
        return { data: r };
      } catch (e) {
        r.errors = ["Something went wrong!"];
        r.navButtonText = "Click to retry.";
        r.navPageID = null;
        commit("setRefill", { r: r, t: payload.t });
        return { data: r };
      }
    },
    async GetRefill({ commit }, payload: { t: number; num: number }) {
      let r: RefillViewModel | any = {};
      try {
        const response = await api.get(`/api/Home/GetRefill?t=${payload.t}&num=${payload.num}`);
        if (response && response.data && response.data.success) {
          r = response.data.data;
          r.errors = response.data ? response.data.errors : [];
          r.navButtonText = response.data ? response.data.navButtonText : "";
          r.navPageID = response.data ? response.data.navPageID : null;
          commit("setRefill", { r: r, t: payload.t });
          return response.data;
        }
        r.errors = response.data ? response.data.errors : [];
        r.navButtonText = response.data ? response.data.navButtonText : "";
        r.navPageID = response.data ? response.data.navPageID : null;
        commit("setRefill", { r: r, t: payload.t });
        return { data: r };
      } catch (e) {
        r.errors = ["Something went wrong!"];
        r.navButtonText = "Click to retry.";
        r.navPageID = null;
        commit("setRefill", { r: r, t: payload.t });
        return { data: r };
      }
    },
    async GetPatientPortal({ commit }): Promise<AjaxReturnViewModel<PatientPortalViewModel> | null> {
      try {
        const vm = await api.get("/api/Home/GetPatientPortal");
        return vm.data;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetCancelOrderView({ commit, state }, payload: { patientUserID: number; patientAffiliateID: number; questionnaireTypeID: number; questionnaireID: number }): Promise<AjaxReturnViewModel<object> | null> {
      try {
        let postUrl = "";
        if (state.Role == "Support") postUrl = `/api/support/GetCancelOrderView?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireTypeID=${payload.questionnaireTypeID}&questionnaireID=${payload.questionnaireID}`;
        if (state.Role == "AffiliateAdmin") postUrl = `/api/affiliateadmin/GetCancelOrderView?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireTypeID=${payload.questionnaireTypeID}&questionnaireID=${payload.questionnaireID}`;
        const vm = await api.post(postUrl);
        return vm.data;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async RestartTherapy({ commit, state }, payload: { patientUserID: number; patientAffiliateID: number; questionnaireTypeID: number }): Promise<AjaxReturnViewModel<object> | null> {
      try {
        let postUrl = "";
        if (state.Role == "Support") postUrl = `/api/support/RestartTherapy?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireTypeID=${payload.questionnaireTypeID}`;
        if (state.Role == "AffiliateAdmin") postUrl = `/api/affiliateadmin/RestartTherapy?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireTypeID=${payload.questionnaireTypeID}`;
        const vm = await api.post(postUrl);
        return vm.data;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetRxHistoryByUserID({ commit, state }, payload: { patientUserID: number; patientAffiliateID: number }): Promise<AjaxReturnViewModel<PatientPortalRxContainer[]> | null> {
      try {
        let getUrl = "/api/Home/GetRxHistoryByUserID";
        if (state.Role == "Support") getUrl = `/api/Support/GetRxHistoryByUserID?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}`;
        if (state.Role == "Physician") getUrl = `/api/physician/GetRxHistoryByUserID?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}`;

        const vm = await api.get(getUrl);
        return vm.data;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async PatientRegister({ commit }, vm: PatientRegisterViewModel): Promise<AjaxReturnViewModel<Patient> | null> {
      try {
        const pVM = await api.post("/api/Home/PatientRegister", vm);
        return pVM.data as AjaxReturnViewModel<Patient>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async CancelOrder({ commit, state }, vm: any): Promise<AjaxReturnViewModel<any> | null> {
      try {
        let postUrl = "";
        if (state.Role == "Support") postUrl = "/api/Support/CancelOrder";
        if (state.Role == "AffiliateAdmin") postUrl = "/api/affiliateadmin/CancelOrder";

        const pVM = await api.post(postUrl, vm);
        return pVM.data as AjaxReturnViewModel<any>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetNextPatientToPhotoVerify({ commit }, endpoint) {
      try {
        const pVM = await api.get(endpoint);
        return pVM.data as AjaxReturnViewModel<PhotoVerifyViewModel>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetNextPatientItem({ commit }, payload: { questionnaireID: number; affiliateID: number }) {
      try {
        const pVM = await api.get(`/api/Physician/GetNextPatientItem?questionnaireID=${payload.questionnaireID}&affiliateID=${payload.affiliateID}`);
        return pVM.data as AjaxReturnViewModel<PhysicianReviewDataViewModel>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetVisitData({ commit }, payload: { startDate: number; endDate: number }) {
      try {
        const pVM = await api.post(`/api/Physician/GetVisitData?startDate=${payload.startDate}&endDate=${payload.endDate}`);
        return pVM.data as AjaxReturnViewModel<VisitScheduleViewModel[]>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetVisitScheduleReport({ commit }, payload: { startDate: number; endDate: number }) {
      try {
        const pVM = await api.post(`/api/Admin/GetVisitScheduleReport?startDate=${payload.startDate}&endDate=${payload.endDate}`);
        return pVM.data as AjaxReturnViewModel<VisitScheduleViewModel[]>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async DenyPhoto({ commit }, payload: { endpoint: string; patientID: number; patientAffiliateID: number; photoReviewerComments: string }) {
      try {
        const pVM = await api.post(`${payload.endpoint}?patientID=${payload.patientID}&patientAffiliateID=${payload.patientAffiliateID}&photoReviewerComments=${payload.photoReviewerComments}`);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async SkipPhoto({ commit }, payload: { endpoint: string; patientID: number; patientAffiliateID: number; photoReviewSkipComments: string }) {
      try {
        const pVM = await api.post(`${payload.endpoint}?patientID=${payload.patientID}&patientAffiliateID=${payload.patientAffiliateID}&photoReviewSkipComments=${payload.photoReviewSkipComments}`);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async ApprovePhoto({ commit }, payload: { endpoint: string; patientID: number; patientAffiliateID: number; photoReviewerComments: string }) {
      try {
        const pVM = await api.post(`${payload.endpoint}?patientID=${payload.patientID}&patientAffiliateID=${payload.patientAffiliateID}&photoReviewerComments=${payload.photoReviewerComments}`);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async SubmitRx({ commit }, payload) {
      try {
        //const pVM = await api.post(`/api/Physician/SubmitRx?questID=${payload.questID}&questAffiliateID=${payload.questAffiliateID}&visitPerformed=${payload.visitPerformed}&physicianComments=${payload.physicianComments}&soapNote=${payload.soapNote}`);
        const pVM = await api.post("/api/Physician/SubmitRx", payload);

        console.log("SubmitRx - Success");
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async ApproveRx({ commit }, payload: { questID: number; questAffiliateID: number; visitPerformed: boolean; physicianComments: string; soapNote: string }) {
      try {
        const pVM = await api.post(`/api/Physician/ApproveRx?questID=${payload.questID}&questAffiliateID=${payload.questAffiliateID}&visitPerformed=${payload.visitPerformed}&physicianComments=${payload.physicianComments}&soapNote=${payload.soapNote}`);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async ApproveRefill({ commit }, payload: { questID: number; questAffiliateID: number; physicianComments: string; soapNote: string }) {
      try {
        const pVM = await api.post(`/api/Physician/ApproveRefill?questID=${payload.questID}&questAffiliateID=${payload.questAffiliateID}&physicianComments=${payload.physicianComments}&soapNote=${payload.soapNote}`);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async DenyRx({ commit }, payload: { questID: number; questAffiliateID: number; visitPerformed: boolean; physicianComments: string; soapNote: string; approveQPRIDs: Array<QuestionnaireProductRxsDetails> }) {
      try {
        const pVM = await api.post(`/api/Physician/DenyRx`, payload);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async DenyRefill({ commit }, payload: { questID: number; questAffiliateID: number; physicianComments: string; soapNote: string }) {
      try {
        const pVM = await api.post(`/api/Physician/DenyRefill?questID=${payload.questID}&questAffiliateID=${payload.questAffiliateID}&physicianComments=${payload.physicianComments}&soapNote=${payload.soapNote}`);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async MarkMessageSent({ commit }, payload: { questID: number; questAffiliateID: number; soapNote: string }) {
      try {
        const pVM = await api.post(`/api/Physician/MarkMessageSent?questID=${payload.questID}&questAffiliateID=${payload.questAffiliateID}&soapNote=${payload.soapNote}`);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async SendVisitReminder({ commit }, payload: { questionnaireID: number; affiliateID: number }) {
      try {
        const pVM = await api.post(`/api/Physician/SendVisitReminder?questionnaireID=${payload.questionnaireID}&affiliateID=${payload.affiliateID}`);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetPatientProfile({ commit, state }, payload: { patientUserID: number; patientAffiliateID: number }) {
      try {
        let getUrl = "/api/Home/GetPatientProfile";
        if (state.Role == "Admin") getUrl = `/api/Admin/GetPatientProfile?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}`;
        if (state.Role == "AffiliateAdmin") getUrl = `/api/affiliateadmin/GetPatientProfile?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}`;
        if (state.Role == "Support") getUrl = `/api/Support/GetPatientProfile?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}`;
        if (state.Role == "Pharmacy") getUrl = `/api/Pharmacy/GetPatientProfile?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}`;
        const pVM = await api.get(getUrl);
        return pVM.data;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async SavePatientProfile({ commit, state }, payload: PatientViewModel) {
      try {
        let postUrl = "/api/Home/SavePatientProfile";
        if (state.Role == "Admin") postUrl = `/api/Admin/SavePatientProfile`;
        if (state.Role == "Support") postUrl = `/api/Support/SavePatientProfile`;
        if (state.Role == "Pharmacy") postUrl = `/api/Pharmacy/SavePatientProfile`;
        const pVM = await api.post(postUrl, payload);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async ToggleSubscription({ commit, state }, t: number) {
      try {
        const pVM = await api.post(`/api/Home/ToggleSubscription?t=${t}`);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async Renew({ commit, state }, t: number) {
      try {
        const pVM = await api.post(`/api/Home/Renew?t=${t}`);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async LookupCustomer({ commit, state }, payload: { lastName: string; email: string; dateOfBirth: number }) {
      try {
        let postUrl = "/api/Pharmacy/LookupCustomer";
        if (state.Role == "Support") postUrl = `/api/Support/LookupCustomer`;
        if (state.Role == "AffiliateAdmin") postUrl = `/api/affiliateadmin/LookupCustomer`;
        const dob = payload.dateOfBirth ? payload.dateOfBirth.toString() : "";
        const email = payload.email ? payload.email.toString() : "";
        const lastName = payload.lastName ? payload.lastName.toString() : "";
        const pVM = await api.post(`${postUrl}?lastName=${lastName}&email=${email}&dateOfBirth=${dob}`);
        if (pVM.data) commit("setCustomerLookup", pVM.data);
        return pVM.data as AjaxReturnViewModel<LookupPatientAsSupportViewModel[]>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async LookupPatient({ commit, state }, payload: { firstName: string; lastName: string; email: string; dateOfBirth: number }) {
      try {
        const postUrl = "/api/physician/LookupPatient";
        const firstName = payload.firstName ? payload.firstName.toString() : "";
        const lastName = payload.lastName ? payload.lastName.toString() : "";
        const email = payload.email ? payload.email.toString() : "";
        const dob = payload.dateOfBirth ? payload.dateOfBirth.toString() : "";

        const pVM = await api.post(`${postUrl}?firstName=${firstName}&lastName=${lastName}&email=${email}&dateOfBirth=${dob}`);
        return pVM.data as AjaxReturnViewModel<LookupPatientAsPhysicianViewModel[]>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetCustomerDetail({ commit, state }, payload: { lastName: string; email: string; dateOfBirth: number; id: number }) {
      console.log("GetCustomerDetail");
      try {
        let getUrl = "/api/Pharmacy/GetCustomerDetail";
        if (state.Role == "Support") getUrl = `/api/Support/GetCustomerDetail`;
        if (state.Role == "AffiliateAdmin") getUrl = `/api/affiliateadmin/GetCustomerDetail`;
        const dob = payload.dateOfBirth ? payload.dateOfBirth.toString() : "";
        const pVM = await api.get(`${getUrl}?lastName=${payload.lastName}&email=${payload.email}&dateOfBirth=${dob}&id=${payload.id}`);
        return pVM.data as AjaxReturnViewModel<CustomerDetailViewModel>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetPatientNotes({ commit }, payload: { patientUserID: number; noteType: number; patientAffiliateID: number }) {
      try {
        const pVM = await api.get(`/api/PatientNotes/GetPatientNotes?patientUserID=${payload.patientUserID}&noteType=${payload.noteType}&patientaffiliateID=${payload.patientAffiliateID}`);
        return pVM.data as AjaxReturnViewModel<PatientNote[]>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async SavePatientNote({ commit }, payload: { id: number; note: string; noteType: number; patientUserID: number; patientAffiliateID: number }) {
      try {
        const pVM = await api.post(`/api/PatientNotes/SavePatientNote?id=${payload.id}&note=${payload.note}&noteType=${payload.noteType}&patientUserID=${payload.patientUserID}&patientaffiliateID=${payload.patientAffiliateID}`);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async ChangeUserName({ commit, state }, payload: { patientUserID: number; patientAffiliateID: number; email: string }) {
      try {
        let postUrl = "";
        if (state.Role == "Support") postUrl = `/api/Support/ChangeUserName?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&email=${payload.email}`;
        if (state.Role == "AffiliateAdmin") postUrl = `/api/affiliateadmin/ChangeUserName?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&email=${payload.email}`;
        const pVM = await api.post(postUrl);
        return pVM.data as AjaxReturnViewModel<CustomerDetailViewModel>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetOrderHistory({ commit, state }, payload: { patientUserID: number; patientAffiliateID: number; questionnaireTypeID: number; rxID: number }) {
      try {
        let getUrl = `/api/Home/GetOrderHistory?questionnaireTypeID=${payload.questionnaireTypeID}&rxID=${payload.rxID}`;
        if (state.Role == "Admin") getUrl = `/api/Admin/GetOrderHistory?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireTypeID=${payload.questionnaireTypeID}&rxID=${payload.rxID}`;
        if (state.Role == "AffiliateAdmin") getUrl = `/api/affiliateadmin/GetOrderHistory?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireTypeID=${payload.questionnaireTypeID}&rxID=${payload.rxID}`;
        if (state.Role == "Support") getUrl = `/api/Support/GetOrderHistory?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireTypeID=${payload.questionnaireTypeID}&rxID=${payload.rxID}`;
        if (state.Role == "Pharmacy") getUrl = `/api/Pharmacy/GetOrderHistory?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireTypeID=${payload.questionnaireTypeID}&rxID=${payload.rxID}`;
        const pVM = await api.get(getUrl);
        return pVM.data as AjaxReturnViewModel<OrderViewModel[]>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async SaveRx({ commit }, rx: Rx) {
      try {
        const pVM = await api.post("/api/Pharmacy/SaveRx", rx);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetShippingCarriers({ commit }) {
      try {
        const response = await api.get("/api/Pharmacy/GetShippingCarriers");
        if (response && response.data && response.data.success) {
          commit("setShippingCarriers", response.data.data);
        }
        return response.data ? response.data : null;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetPharmShoppingCart({ commit }, id): Promise<AjaxReturnViewModel<ShoppingCartViewModel> | null> {
      try {
        const cart = await api.get("/api/pharmacy/GetShoppingCart?id=" + id);
        return cart.data as AjaxReturnViewModel<ShoppingCartViewModel>;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async SaveShoppingCartTrackingNumber({ commit }, data): Promise<AjaxReturnViewModel<boolean> | null> {
      try {
        const cart = await api.post("/api/pharmacy/UpdateOTCTracking", data);
        return cart.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async SaveTrackingNumber({ commit }, payload: { fillIdentifier: string; shippingCarrierID: number; trackingNumber: string }) {
      try {
        const pVM = await api.post(`/api/Pharmacy/SaveTrackingNumber?fillIdentifier=${payload.fillIdentifier}&shippingCarrierID=${payload.shippingCarrierID}&trackingNumber=${payload.trackingNumber}`);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async UserEmailConfirmation({ commit, state }, payload: { code: string }): Promise<AjaxReturnViewModel<boolean> | null> {
      try {
        const resp = await api.post("/api/Home/UserEmailConfirmation", payload);
        if (resp) {
          return resp.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async NewUserInvite({ commit, state }, payload: { code: string }): Promise<AjaxReturnViewModel<boolean> | null> {
      try {
        const resp = await api.post("/api/Home/NewUserInvite", payload);
        if (resp) {
          return resp.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async UserForgotPassword({ commit, state }, payload: { username: string; token: string }): Promise<AjaxReturnViewModel<string> | null> {
      try {
        const resp = await api.post(`/api/Home/UserForgotPassword`, { username: payload.username, token: payload.token });
        if (resp) {
          return resp.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async UserPasswordReset({ commit, state }, payload: { code: string; newPassword: string }): Promise<AjaxReturnViewModel<boolean> | null> {
      try {
        const resp = await api.post("/api/Home/UserPasswordReset", { newPassword: payload.newPassword, code: payload.code }, { headers: { Authorization: `Bearer ${payload.code}` } });
        if (resp) {
          return resp.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async NewUserPasswordReset({ commit, state }, payload: { code: string; newPassword: string }): Promise<AjaxReturnViewModel<boolean> | null> {
      try {
        const resp = await api.post("/api/Home/NewUserPasswordReset", { newPassword: payload.newPassword, code: payload.code }, { headers: { Authorization: `Bearer ${payload.code}` } });
        if (resp) {
          return resp.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async UserIs2FA({ commit }, code: string) {
      try {
        const response = await axios.post(`/api/Home/UserIs2FA?code=${code}`);
        return response?.data?.data;
      } catch (e) {
        return null;
      }
    },
    async UpdateUTM({ commit }, utm) {
      try {
        const response = await api.post("/api/Home/UpdateUTM", utm);
        if (response?.data?.data) {
          commit("setUTM", null);
        }
        return response.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetPharmacySchedule({ commit, state }) {
      try {
        const resp = await api.get("/api/Pharmacy/GetPharmacySchedule");
        if (resp && resp.data && resp.data.success) {
          return resp.data.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async UpdatePharmacySchedule({ commit }, ps: PharmacySchedule[]) {
      try {
        const pVM = await api.post("/api/Pharmacy/UpdatePharmacySchedule", ps);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetPharmacySpecialHours({ commit, state }) {
      try {
        const resp = await api.get("/api/pharmacy/GetSpecialHours");
        if (resp && resp.data && resp.data.success) {
          return resp.data.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async UpdatePharmacySpecialHours({ commit }, ps: PharmacySpecialHours[]) {
      try {
        const pVM = await api.post("/api/Pharmacy/UpdateSpecialHours", ps);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetUsersForAdmin({ commit }, payload) {
      try {
        const resp = await api.post("/api/Admin/GetUsers", payload);
        if (resp && resp.data && resp.data.success) {
          return resp.data as AjaxReturnViewModel<UsersForAdminViewModel[]>;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async GetPromotionData(context, { endpoint, AffiliateID, Name, Code }) {
      try {
        const resp = await api.get(endpoint, {
          params: {
            AffiliateID,
            Name,
            Code,
          },
        });
        if (resp && resp.data && resp.data.success) {
          return resp.data.data;
        } else {
          throw new Error("Failed to get promotions");
        }
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    async GetAffiliateQuestionaireData({ commit, state }, { endpoint, affiliateID }) {
      try {
        const resp = await api.get(`${endpoint}/${affiliateID}`);
        if (resp && resp.data && resp.data.success) {
          return resp.data.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async GetAffiliateNames({ commit, state }, endpoint) {
      try {
        const resp = await api.get(endpoint);
        if (resp && resp.data) {
          const affiliateNames = Object.entries(resp.data).map(([key, value]) => ({ id: key, name: value }));
          return affiliateNames;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async GetAllReportNames({ commit, state }, endpoint) {
      try {
        const resp = await api.get(endpoint);
        if (resp && resp.data) {
          const reportNames = Object.entries(resp.data).map(([key, value]) => ({ id: key, name: value }));
          return reportNames;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async GetAllAffiliateReportNames({ commit, state }, endpoint) {
      try {
        const resp = await api.get(endpoint);
        if (resp && resp.data) {
          return resp.data.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async GetPharmacyNames({ commit, state }) {
      try {
        const resp = await api.get("/api/admin/GetPharmacyNames");
        if (resp && resp.data) {
          const pharmacyNames = Object.entries(resp.data).map(([key, value]) => ({ id: key, name: value }));
          return pharmacyNames;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async GetVitaminSupplierNames({ commit, state }) {
      try {
        const resp = await api.get("/api/admin/GetVitaminSupplierNames");
        if (resp && resp.data) {
          const viteSupNames = Object.entries(resp.data).map(([key, value]) => ({ id: key, name: value }));
          return viteSupNames;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async GetQuestionnaireTypes({ commit, state }) {
      try {
        const resp = await api.get("/api/admin/GetQuestionnaireTypes");
        if (resp && resp.data) {
          const questTypes = Object.entries(resp.data).map(([key, value]) => ({ id: key, name: value }));
          return questTypes;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async CreateAdminUser({ commit }, payload) {
      try {
        const pVM = await api.post("/api/admin/CreateAdminUser", payload);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async CreateAffiliateAdminUser({ commit }, au: AdminUserViewModel) {
      try {
        const pVM = await api.post("/api/admin/CreateAffiliateAdminUser", au);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async CreateAPIUser({ commit }, au: AdminUserViewModel) {
      try {
        const pVM = await api.post("/api/admin/CreateAPIUser", au);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async CreatePatientUser({ commit }, au: AdminUserViewModel) {
      try {
        const pVM = await api.post("/api/admin/CreatePatientUser", au);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async CreatePharmacyUser({ commit }, payload) {
      try {
        const pVM = await api.post("/api/admin/CreatePharmacyUser", payload);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async CreatePhysicianUser({ commit }, payload) {
      try {
        const pVM = await api.post("/api/admin/CreatePhysicianUser", payload);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async CreateVitaminSupplierUser({ commit }, au: AdminUserViewModel) {
      try {
        const pVM = await api.post("/api/admin/CreateVitaminSupplierUser", au);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async UserPasswordChange({ commit, state }, payload: { p: string; np: string; c: string; t: string; m: TwoFactorTypeEnum }): Promise<AjaxReturnViewModel<boolean> | null> {
      try {
        const resp = await api.post("/api/members/changePassword", payload);
        if (resp) {
          return resp.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },

    async AdminPasswordChange({ commit, state }, payload: { np: string; patientUserID: number; patientAffiliateID: number }) {
      try {
        let postUrl = "";
        if (state.Role === "Support") postUrl = `/api/support/AdminPasswordChange`;
        if (state.Role === "AffiliateAdmin") postUrl = `/api/affiliateadmin/AdminPasswordChange`;
        if (state.Role === "Pharmacy") postUrl = `/api/pharmacy/AdminPasswordChange`;

        const r = await api.post(postUrl, payload);
        return r.data;
      } catch (e) {
        return null;
      }
    },

    async UpdateEmail({ commit }, vm: AdminEmailUpdateViewModel) {
      try {
        const pVM = await api.post("/api/admin/UpdateEmail", vm);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async UpdateAdminUser({ commit }, auvm: AdminUserViewModel[]) {
      try {
        const pVM = await api.post("/api/admin/UpdateAdminUser", auvm);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async UpdateAffiliateAdminUser({ commit }, auvm: AdminUserViewModel[]) {
      try {
        const pVM = await api.post("/api/admin/UpdateAffiliateAdminUser", auvm);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async UpdateAPIUser({ commit }, auvm: AdminUserViewModel[]) {
      try {
        const pVM = await api.post("/api/admin/UpdateAPIUser", auvm);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async UpdatePatientUser({ commit }, auvm: AdminUserViewModel[]) {
      try {
        const pVM = await api.post("/api/admin/UpdatePatientUser", auvm);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async UpdatePharmacyUser({ commit }, auvm: AdminUserViewModel[]) {
      try {
        const pVM = await api.post("/api/admin/UpdatePharmacyUser", auvm);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async UpdateVitaminSupplierUser({ commit }, auvm: AdminUserViewModel[]) {
      try {
        const pVM = await api.post("/api/admin/UpdateVitaminSupplierUser", auvm);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async UpdatePhysicianUser({ commit }, auvm: AdminUserViewModel[]) {
      try {
        const pVM = await api.post("/api/admin/UpdatePhysicianUser", auvm);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetPhysicianState({ commit, state }, userID) {
      try {
        const resp = await api.get(`/api/admin/GetPhysicianState/${userID}`);
        if (resp && resp.data && resp.data.success) {
          return resp.data.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async GetUserAccess({ commit, state }, userID) {
      try {
        const resp = await api.get(`/api/admin/GetUserAccess/${userID}`);
        if (resp && resp.data && resp.data.success) {
          return resp.data.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async GetUserReportAccess({ commit, state }, userID) {
      try {
        const resp = await api.get(`/api/admin/GetUserReportAccess/${userID}`);
        if (resp && resp.data && resp.data.success) {
          return resp.data.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async GetPhysicianData({ commit, state }, userID) {
      try {
        const resp = await api.get(`/api/admin/GetPhysicianData/${userID}`);
        if (resp && resp.data && resp.data.success) {
          return resp.data.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async CreatePromotion({ commit }, { endpoint, auvm }: { endpoint: string; auvm: NewPromotionViewModel[] }) {
      try {
        const pVM = await api.post(endpoint, auvm);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async UpdatePromotion({ commit }, { endpoint, auvm }: { endpoint: string; auvm: PromotionRx[] }) {
      try {
        const pVM = await api.post(endpoint, auvm);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async UpdatePromotionStatus({ commit }, { endpoint, statusUpdate }) {
      try {
        const response = await api.post(endpoint, statusUpdate);
        return response.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetReportNames({ commit, state }, endpoint) {
      try {
        const resp = await api.get(endpoint);
        if (resp && resp.data) {
          return resp.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async GetReportParameters({ commit, state }, { reportId, endpoint }) {
      try {
        const resp = await api.get(endpoint + reportId);
        if (resp && resp.data && resp.data.success) {
          return resp.data.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async SubmitReportData({ commit }, { submissionData, endpoint }) {
      try {
        const resp = await api.post(endpoint, submissionData);
        if (resp && resp.data && resp.data.success) {
          return resp.data as AjaxReturnViewModel<ReportDataViewModel>;
        }
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetChartData({ commit, state }) {
      try {
        const resp = await api.get("/api/affiliateadmin/GetChartData");
        if (resp && resp.data) {
          return resp.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async GetUnreadMessages({ commit }, { endpoint }) {
      try {
        const resp = await api.post(endpoint);
        if (resp && resp.data && resp.data.success) {
          return resp.data;
        }
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async GetTotalOrdersShipped({ commit, state }) {
      try {
        const resp = await api.post("/api/pharmacy/GetTotalOrdersShipped");
        if (resp && resp.data) {
          return resp.data;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    },
    async CancelCharge({ commit, state }, payload: { patientUserID: number; patientAffiliateID: number; subscriptionID: number }) {
      try {
        let postUrl = "";
        if (state.Role == "Support") postUrl = `/api/support/CancelScheduledCharge?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&subscriptionID=${payload.subscriptionID}`;
        if (state.Role == "AffiliateAdmin") postUrl = `/api/affiliateadmin/CancelScheduledCharge?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&subscriptionID=${payload.subscriptionID}`;
        const pVM = await api.post(postUrl);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async UpdateScheduledCharge({ commit, state }, payload: { patientUserID: number; patientAffiliateID: number; subscriptionID: number; changeChargeAmount: number; changeChargeNote: string; paymentTransactionType: string }) {
      try {
        let postUrl = "";
        if (state.Role == "Support") postUrl = `/api/support/UpdateScheduledCharge?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&subscriptionID=${payload.subscriptionID}&changeChargeAmount=${payload.changeChargeAmount}&changeChargeNote=${payload.changeChargeNote}&paymentTransactionType=${payload.paymentTransactionType}`;
        if (state.Role == "AffiliateAdmin") postUrl = `/api/affiliateadmin/UpdateScheduledCharge?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&subscriptionID=${payload.subscriptionID}&changeChargeAmount=${payload.changeChargeAmount}&changeChargeNote=${payload.changeChargeNote}&paymentTransactionType=${payload.paymentTransactionType}`;
        const pVM = await api.post(postUrl);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async RefundCharge({ commit, state }, payload: { patientUserID: number; patientAffiliateID: number; questionnaireID: number; transactionID: string; refundAmount: number; paymentTransactionType: string; refundNote: string }) {
      try {
        let postUrl = "";
        if (state.Role == "Support") postUrl = `/api/support/RefundCharge?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireID=${payload.questionnaireID}&transactionID=${payload.transactionID}&refundAmount=${payload.refundAmount}&paymentTransactionType=${payload.paymentTransactionType}&refundNote=${payload.refundNote}`;
        if (state.Role == "AffiliateAdmin")
          postUrl = `/api/affiliateadmin/RefundCharge?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireID=${payload.questionnaireID}&transactionID=${payload.transactionID}&refundAmount=${payload.refundAmount}&paymentTransactionType=${payload.paymentTransactionType}&refundNote=${payload.refundNote}`;
        const pVM = await api.post(postUrl);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async UpdateCreditCard({ commit }, authToken) {
      try {
        const pVM = await api.post(`/api/home/UpdateCreditCard?authToken=${authToken}`);
        return pVM.data as AjaxReturnViewModel<boolean>;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async HoldQuestionnaire({ commit, state }, payload: { patientUserID: number; patientAffiliateID: number; questionnaireID: number; hold: boolean }): Promise<AjaxReturnViewModel<boolean> | null> {
      try {
        let postUrl = "";
        if (state.Role == "Support") postUrl = `/api/support/HoldQuestionnaire?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireID=${payload.questionnaireID}&hold=${payload.hold}`;
        if (state.Role == "AffiliateAdmin") postUrl = `/api/affiliateadmin/HoldQuestionnaire?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}&questionnaireID=${payload.questionnaireID}&hold=${payload.hold}`;
        const r = await api.post(postUrl);
        return r.data;
      } catch (e) {
        return null;
      }
    },
    async GetAffiliatesForReportManagement({ commit }) {
      try {
        const r = await api.get(`/api/reportmanagement/GetAffiliatesForReportManagement`);
        return r.data.data;
      } catch (e) {
        return null;
      }
    },
    async GetSprocList({ commit }) {
      try {
        const r = await api.get(`/api/reportmanagement/GetSprocList`);
        return r.data.data;
      } catch (e) {
        return null;
      }
    },
    async CreateReport({ commit }, payload: OutgoingReport): Promise<AjaxReturnViewModel<boolean> | null> {
      try {
        const r = await api.post(`/api/reportmanagement/CreateReport`, payload);
        commit("setReportManagementReportList", []);
        return r.data;
      } catch (e) {
        return null;
      }
    },
    async GetReportsForManagement({ commit }) {
      try {
        const r = await api.get(`/api/reportmanagement/GetReportsForManagement`);
        commit("setReportManagementReportList", r.data.data);
        commit("setReportManagementErrorList", r.data.errors);
        return null;
      } catch (e) {
        return null;
      }
    },
    async UpdateReport({ commit }, payload: ReportDataManagementViewModel): Promise<AjaxReturnViewModel<boolean> | null> {
      try {
        const r = await api.post(`/api/reportmanagement/UpdateReport`, payload);
        commit("setReportManagementReportList", []);
        return r.data;
      } catch (e) {
        return null;
      }
    },
    async DeleteReport({ commit }, payload: number): Promise<AjaxReturnViewModel<boolean> | null> {
      try {
        console.log(`payload id: ${payload}`);
        const r = await api.post(`/api/reportmanagement/DeleteReport/${payload}`);
        return r.data;
      } catch (e) {
        return null;
      }
    },
    async GetAllUsersForReportManagement({ commit }) {
      try {
        const r = await api.get(`/api/reportmanagement/GetAllUsersForReportManagement`);
        return r.data;
      } catch (e) {
        return null;
      }
    },
    // async GetMaintenanceStatus({ commit }) {
    //   try {
    //     const r = await api.get(`/api/maintenance/GetMaintenanceStatus`);
    //     commit("setMaintenanceDetails", r.data.data);
    //     return r.data;
    //   } catch (e) {
    //     return null;
    //   }
    // },
    // async PostMaintenance({ commit }, payload: any) {
    //   try {
    //     const r = await api.post(`/api/maintenance/PostMaintenance`, payload);
    //     return r.data.success;
    //   } catch (e) {
    //     return null;
    //   }
    // },
    // async SetAffiliateMaintenanceModeContent({ commit }, payload: any) {
    //   try {
    //     const r = await api.post(`/api/maintenance/SetAffiliateMaintenanceModeContent_${payload.useDefault}`, JSON.stringify(payload.html), {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     });
    //     return r.data.success;
    //   } catch (e) {
    //     return null;
    //   }
    // },
    // async GetAllMaintenance({ commit }) {
    //   try {
    //     const r = await api.get(`/api/maintenance/GetAllMaintenance`);
    //     return r.data;
    //   } catch (e) {
    //     return null;
    //   }
    // },
    // async GetAffiliatesForMaintenanceAssignation({ commit }) {
    //   try {
    //     const r = await api.get(`/api/maintenance/GetAffiliatesForMaintenanceAssignation`);
    //     return r.data;
    //   } catch (e) {
    //     return null;
    //   }
    // },
    // async GetDefaultMaintenanceHTML({ commit }) {
    //   try {
    //     const r = await api.get(`/api/maintenance/GetDefaultMaintenanceHTML`);
    //     return r.data.data;
    //   } catch (e) {
    //     return null;
    //   }
    // },
    // async DeleteMaintenance({ commit }, payload: number) {
    //   try {
    //     const r = await api.post(`/api/maintenance/DeleteMaintenance_${payload}`);
    //     return r.data;
    //   } catch (e) {
    //     return null;
    //   }
    // },
    // async GetMaintenanceIntervals({ commit }) {
    //   try {
    //     const r = await api.get("/api/maintenance/GetMaintenanceIntervals");
    //     if (r && r.data && r.data.data && r.data.data.notWithinMaintenanceWindow && r.data.data.withinMaintenanceWindow) {
    //       commit("setNotWithinMaintenanceWindow", r.data.data.notWithinMaintenanceWindow);
    //       commit("setWithinMaintenanceWindow", r.data.data.withinMaintenanceWindow);
    //     }
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    // async GetAllCallToActions({ commit }, payload: boolean) {
    //   // If true, then it's an admin user and we're getting all to display in the table.
    //   try {
    //     const r = await api.get(`/api/calltoaction/all/${payload}`);
    //     commit("setCallToActionArray", r.data.data);
    //     return r.data.data;
    //   } catch (e) {
    //     return null;
    //   }
    // },
    // async GetCallToActionByID({ commit }, ctaID: number) {
    //   try {
    //     const r = await api.get(`/api/calltoaction/details/${ctaID}`);
    //     return r.data.data;
    //   } catch (e) {
    //     return null;
    //   }
    // },
    // async PostCallToAction({ commit }, payload: any) {
    //   let errorMessage = "";
    //   try {
    //     if (payload.id) {
    //       const r = await api.post(`/api/calltoaction/update`, payload);
    //       errorMessage = r.data.errors;
    //       return r.data.success;
    //     } else {
    //       const r = await api.post(`/api/calltoaction/new`, payload);
    //       errorMessage = r.data.errors;
    //       this.commit("setCallToActionArray", []);
    //       return r.data.success;
    //     }
    //   } catch (e) {
    //     return errorMessage;
    //   }
    // },
    async GetWeightTrackerEntries({ commit }) {
      try {
        const result = await api.get("api/home/GetWeightTrackerEntries");
        return result.data.data;
      } catch (e) {
        return null;
      }
    },
    async GetFullPatientWidgetData({ commit }) {
      try {
        const result = await api.get("api/home/GetFullPatientWidgetData");
        commit("setFullPatientWidgetData", result.data.data);
        return result.data.data;
      } catch (e) {
        return null;
      }
    },
    async GetGoalWeight({ commit }) {
      try {
        const result = await api.get("api/home/GetGoalWeight");
        return result.data.data;
      } catch (e) {
        return null;
      }
    },
    async SetGoalWeight({ commit }, payload: any) {
      try {
        const result = await api.post("api/home/SetGoalWeight", payload);
        return result.data.success;
      } catch (e) {
        return null;
      }
    },
    async AddWeightLogEntry({ commit }, payload: any) {
      try {
        const result = await api.post("api/home/AddWeightLogEntry", payload);
        return result.data.success;
      } catch (e) {
        return null;
      }
    },
    async GetPatientVitals({ commit }, payload: PatientVitalViewModel) {
      try {
        console.log(payload);
        const resp = await api.post("/api/physician/GetPatientVitals", payload);
        if (resp && resp.data && resp.data.success) {
          return resp.data as AjaxReturnViewModel<PatientVitalViewModel[]>;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async DisableTwoFactor({ commit, state }, payload: { patientUserID: number; patientAffiliateID: number }) {
      try {
        let postUrl = "";
        if (state.Role == "Support") postUrl = `/api/support/DisableTwoFactor?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}`;
        if (state.Role == "AffiliateAdmin") postUrl = `/api/affiliateadmin/DisableTwoFactor?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}`;
        if (state.Role == "Pharmacy") postUrl = `/api/pharmacy/DisableTwoFactor?patientUserID=${payload.patientUserID}&patientAffiliateID=${payload.patientAffiliateID}`;
        const r = await api.post(postUrl);
        return r.data;
      } catch (e) {
        return null;
      }
    },
  },
  modules: {},
});
